import {
  UserOutlined,
  ControlOutlined,
  RollbackOutlined,
  UserSwitchOutlined,
  DatabaseOutlined,
  AppstoreAddOutlined,
  UnlockOutlined,
  GlobalOutlined,
} from "@ant-design/icons";
import { useEffect, useState, useCallback } from "react";
import { useSelector } from "react-redux";
import store from "../../store/store";
import {
  getPayload,
  getPermisos,
  organizarSections,
  getPermisosByModule,
  setToken,
  setTokenBack,
} from "../../utils/auth";
import { setSearch } from "../../store/actions/setSearch";
import { Menu } from "antd";
import "../../styles/div-scroll.css";
import ChangeLanguaje from "../../new-hooks/changeLanguaje";
import Hook from "../../new-hooks/companiesData";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "../../router/AppRouter";
import Swal from "sweetalert2";

const { SubMenu, Item } = Menu;

interface LanguageOption {
  val: string;
  idiom: string;
}

const SideMenu = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const changeLanguageHook = ChangeLanguaje();

  const payload = getPayload();
  const permisos = getPermisos();
  const permisosSubmenu = organizarSections(permisos);

  const state = useSelector((state: any) => state);
  const reduxState = useSelector((state: any) => state.generalSearch);

  const [isChangingLanguage, setIsChangingLanguage] = useState(false);
  const [languageOptions] = useState<LanguageOption[]>([
    { val: "es", idiom: t("spanish") },
    { val: "en", idiom: t("english") },
    { val: "pt", idiom: t("Portuguese") },
  ]);

  // eslint-disable-next-line
  const data: any = Hook(reduxState);

  const handleRedirect = useCallback(
    (event: any, path: string) => {
      const newWindow = event?.domEvent?.ctrlKey || event?.domEvent?.metaKey;
      localStorage.setItem("HOME_BI", "false");

      if (newWindow) {
        window.open(path, "_blank");
      } else {
        store.dispatch(setSearch({ type: "totalReset", payload: {} }));
        navigate(path);
      }
    },
    [navigate]
  );

  const handleLogout = useCallback(() => {
    localStorage.clear();
    window.location.replace("/login");
  }, []);

  const handleLanguageChange = useCallback(
    async (languageKey: string) => {
      if (isChangingLanguage) return;

      try {
        setIsChangingLanguage(true);

        const response = await changeLanguageHook({
          languaje: languageKey,
        });

        if (response?.data?.changeLenguaje) {
          const { token, back_token } = response.data.changeLenguaje;

          // Actualizar tokens
          setToken(token);
          setTokenBack(back_token);

          // Actualizar el idioma en localStorage y i18n
          await changeLanguageHook(languageKey);
          localStorage.setItem("language", languageKey);
          window.location.reload();
          await Swal.fire({
            title: t("msgIdiom"),
            icon: "success",
            timer: 1500,
            showConfirmButton: false,
          });

          // Solo recargar si es necesario
          if (localStorage.getItem("language") !== languageKey) {
            window.location.reload();
          }
        }
      } catch (error: any) {
        console.error("Error changing language:", error);
        await Swal.fire({
          title: t("error_message1"),
          text: error?.message || t("unknown_error"),
          icon: "error",
        });
      } finally {
        setIsChangingLanguage(false);
      }
    },
    [isChangingLanguage, changeLanguageHook, t]
  );

  // Efecto para manejar permisos de pago
  useEffect(() => {
    if (location.pathname === "/home/pay") {
      const payPermisos = getPermisosByModule("pagos", undefined);

      if (
        payPermisos &&
        !payPermisos.includes("SELECT_MULTIASIGNEE") &&
        !state.generalSearch.asignee
      ) {
        store.dispatch(
          setSearch({
            type: "setFiltersControl",
            payload: {
              ...state.generalSearch,
              asignee: [payload.id],
            },
          })
        );
      }
    }
  }, [payload.id, state.generalSearch, location.pathname]);

  return (
    <>
      <Menu key="menuside" mode="inline" theme="dark">
        <SubMenu
          key="users"
          icon={<UserOutlined style={{ fontSize: 17 }} />}
          style={{
            height: "fit-content",
            maxHeight: 30,
            alignItems: "center",
            justifyContent: "center",
          }}
          title={payload.names}
        >
          <Item key="name" disabled icon={<UserOutlined />}>
            {payload?.names ?? ""}
          </Item>
          <Item key="company" disabled icon={<DatabaseOutlined />}>
            {payload?.company_name ?? ""}
          </Item>
          <Item key="username" disabled icon={<UserSwitchOutlined />}>
            {payload?.nickname ?? ""}
          </Item>
          <Item key="rol" disabled icon={<ControlOutlined />}>
            {payload?.rol ?? ""}
          </Item>

          <SubMenu
            key="lenguaje"
            title={t("language")}
            style={{
              height: "fit-content",
              maxHeight: 30,
              alignItems: "center",
              justifyContent: "center",
            }}
            icon={<GlobalOutlined />}
          >
            {languageOptions.map(({ val, idiom }) => (
              <Item
                key={val}
                onClick={() => handleLanguageChange(val)}
                disabled={isChangingLanguage}
              >
                {idiom}
              </Item>
            ))}
          </SubMenu>

          <Item
            key="password"
            icon={<UnlockOutlined />}
            onClick={(event) => handleRedirect(event, "/home/changepassword")}
          >
            {t("update_password")}
          </Item>

          <Item key="logout" icon={<RollbackOutlined />} onClick={handleLogout}>
            {t("SignOff")}
          </Item>
        </SubMenu>
      </Menu>

      <Menu key="adminmenu" mode="inline" theme="dark">
        {permisosSubmenu.administration.length > 0 && (
          <SubMenu
            key="admin"
            icon={<AppstoreAddOutlined style={{ fontSize: 17 }} />}
            title={t("management")}
          >
            {permisosSubmenu.administration.map((element: any) => {
              const shortcut = element?.shortcut?.length
                ? `${element.shortcut[0]} + ${element.shortcut[1] ?? ""}`
                : "";

              return (
                <Item
                  key={element.id.toLowerCase()}
                  style={{ height: "auto" }}
                  onClick={(event) =>
                    handleRedirect(event, "/home" + element.path)
                  }
                  title={`${element.title} ${shortcut}`}
                  icon={state.sidebar ? element.short_name : undefined}
                >
                  {!state.sidebar && element.short_name}
                </Item>
              );
            })}
          </SubMenu>
        )}
      </Menu>

      <Menu key="menupermisos" theme="dark" style={{ overflow: "auto" }}>
        {permisosSubmenu.none.map((element: any) => {
          const shortcut = element?.shortcut?.length
            ? `${element.shortcut[0]} + ${element.shortcut[1]}`
            : "";

          return (
            <Item
              key={element.id.toLowerCase()}
              style={{
                height: "fit-content",
                maxHeight: 30,
                alignItems: "center",
                justifyContent: "center",
              }}
              onClick={(event) => handleRedirect(event, "/home" + element.path)}
              title={`${element.title} ${shortcut}`}
              icon={
                <span
                  style={{ textAlign: "center", fontSize: 17 }}
                  className="material-icons"
                >
                  {element.icon}
                </span>
              }
            >
              {!state.sidebar && element.short_name}
            </Item>
          );
        })}
      </Menu>
    </>
  );
};

export default SideMenu;
