import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import traslationEn from "./assets/locales/en/translation.json";
import traslationEs from "./assets/locales/es/translation.json";
import translationPt from "./assets/locales/pt/translation.json";

// Recursos de idiomas
const resources = {
  en: { translation: traslationEn },
  es: { translation: traslationEs },
  pt: { translation: translationPt },
};

// Función para obtener el idioma del localStorage
export const getCurrentLanguage = (): string => {
  try {
    const storedLanguage = localStorage.getItem("language");
    return storedLanguage || navigator.language;
  } catch (error) {
    console.error("Error getting language from localStorage:", error);
    return navigator.language;
  }
};

// Inicializar i18n
const initializeI18n = () => {
  const currentLang = getCurrentLanguage();

  i18n.use(initReactI18next).init({
    resources,
    lng: currentLang,
    fallbackLng: "es",
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: false,
    },
  });

  // Suscribirse a cambios en el localStorage para detectar cambios de idioma
  window.addEventListener("storage", (event) => {
    if (event.key === "language") {
      // Cambiado de 'token' a 'language'
      const newLang = getCurrentLanguage();
      if (newLang !== i18n.language) {
        i18n.changeLanguage(newLang);
      }
    }
  });
};

initializeI18n();

export const forceLanguageUpdate = async () => {
  const newLang = getCurrentLanguage();
  if (newLang !== i18n.language) {
    await i18n.changeLanguage(newLang);
  }
};

export default i18n;
