/* eslint-disable import/no-anonymous-default-export */
import { Children, lazy, Suspense } from "react";
import { Navigate } from "./AppRouter";

import SuspenseLayout from "./SuspenseLayout";
import ProtectedRoute from "./ProtectedRoute";

import Home from "../components/pages/home";
import ErrorLayout from "./ErrorLayout";
const Dashboards = lazy(() => import("../components/dashboards/Dashboards"));

const Pay = lazy(() => import("../components/pay/Pay"));
const CredentialsRestore = lazy(
  () => import("../components/pages/CredentialsRestore")
);
const Calculator = lazy(
  () => import("../components/new-organismos/Calculator")
);
const Login = lazy(() => import("../components/pages/login"));
const Dashboard = lazy(() => import("../components/templates/dashboard"));
const NewPassword = lazy(
  () => import("../components/new-molecules/views/newPassword/NewPassword")
);
const UploadBills = lazy(() => import("../components/uploadbills/UploadBills"));
const Companies = lazy(() => import("../components/templates/Companies"));
const UserAdmin = lazy(() => import("../components/templates/userAdmin"));
const Miscellaneous = lazy(
  () => import("../components/templates/miscellaneous")
);
const Downloads = lazy(() => import("../components/templates/Downloads"));
const Uploader = lazy(() => import("../components/uploader/Uploader"));
const AuditPane = lazy(() => import("../components/templates/AuditPane"));

const BIReports = lazy(() => import("../components/bireports/BIReports"));
const CashTabs = lazy(() => import("../components/templates/cashTabs"));
const Fileviewer = lazy(() => import("../components/fileviewer/Fileviewer"));
const AdminPoints = lazy(() => import("../components/analysis/AdminPoints"));
const PayStatsModal = lazy(
  () => import("../components/statistics/PayStatistics")
);
const Assets = lazy(() => import("../components/templates/Assets"));
const VertebotsAdmins = lazy(
  () => import("../components/templates/VertebotsAdmins")
);
const BudgetClient = lazy(
  () => import("../components/budgetclient/BudgetClient")
);
const Guruve = lazy(() => import("../components/templates/GuruveTabs"));

const ClientsTabs = lazy(() => import("../components/templates/clientsTabs"));

const Ecoeficiencia = lazy(
  () => import("../components/ecoeficiencia/Ecoeficiencia")
);

const Reports = lazy(() => import("../components/adminReports/Reports"));
const OdooClaim = lazy(() => import("../components/OdooClaim/OdooClaim"));
const SearchBillsTabs = lazy(
  () => import("../components/searchbills/SearchBills")
);
const ReviewBillsTabs = lazy(() => import("../components/review/Review"));
const UploaderStatistics = lazy(
  () => import("../components/statistics/UploaderStatistics")
);
const EmailsTabs = lazy(() => import("../components/emails/EmailsTabs"));

export default [
  {
    path: "/",
    element: <Navigate to="/home" replace />,
  },
  {
    path: "/credentials",
    //exact: true,
    element: (
      <SuspenseLayout>
        <CredentialsRestore />
      </SuspenseLayout>
    ),
  },
  {
    path: "/calculators",
    //exact: true,
    element: (
      <SuspenseLayout>
        <Calculator />
      </SuspenseLayout>
    ),
  },
  {
    path: "/login",
    //exact: true,
    element: (
      <SuspenseLayout>
        <Login />
      </SuspenseLayout>
    ),
    //canActivate: [IsLogged],
  },
  {
    element: <ProtectedRoute />,
    children: [
      {
        path: "/home",
        element: (
          <ErrorLayout>
            <SuspenseLayout>
              <Home />
            </SuspenseLayout>
          </ErrorLayout>
        ),
        children: [
          {
            path: "/home/",
            element: <Navigate to="/home/dashboard" replace />,
          },
          {
            path: "/home/dashboard",
            element: (
              <SuspenseLayout>
                <Dashboard />
              </SuspenseLayout>
            ),
          },
          {
            path: "/home/changepassword",
            element: (
              <SuspenseLayout>
                <NewPassword />
              </SuspenseLayout>
            ),
          },
          {
            path: "/home/statusbills",
            element: <Navigate to="/home/dashboards/statusbills" replace />,
          },
          {
            path: "/home/statuspayments",
            element: <Navigate to="/home/dashboards/statuspayments" replace />,
          },
          {
            path: "/home/dashboards",
            element: (
              <SuspenseLayout>
                <Dashboards />
              </SuspenseLayout>
            ),
          },
          {
            path: "/home/dashboards/statusbills",
            element: (
              <SuspenseLayout>
                <Dashboards />
              </SuspenseLayout>
            ),
          },
          {
            path: "/home/dashboards/statusrates",
            element: (
              <SuspenseLayout>
                <Dashboards />
              </SuspenseLayout>
            ),
          },
          {
            path: "/home/dashboards/statuspayments",
            element: (
              <SuspenseLayout>
                <Dashboards />
              </SuspenseLayout>
            ),
          },
          {
            path: "/home/load",
            element: (
              <SuspenseLayout>
                <UploadBills />
              </SuspenseLayout>
            ),
          },
          {
            path: "/home/clients",
            element: <Navigate to="/home/clients/places" replace />,
          },
          {
            path: "/home/clients/places",
            element: (
              <SuspenseLayout>
                <ClientsTabs />
              </SuspenseLayout>
            ),
          },
          {
            path: "/home/clients/accounts",
            element: (
              <SuspenseLayout>
                <ClientsTabs />
              </SuspenseLayout>
            ),
          },
          {
            path: "/home/clients/groups",
            element: (
              <SuspenseLayout>
                <ClientsTabs />
              </SuspenseLayout>
            ),
          },

          {
            path: "/home/pay",
            element: (
              <SuspenseLayout>
                <Pay />
              </SuspenseLayout>
            ),
          },
          {
            path: "/home/searchbills",
            element: (
              <SuspenseLayout>
                <SearchBillsTabs />
              </SuspenseLayout>
            ),
          },
          {
            path: "/home/searchbills/emails",
            element: (
              <SuspenseLayout>
                <SearchBillsTabs />
              </SuspenseLayout>
            ),
          },
          {
            path: "/home/companies",
            element: (
              <SuspenseLayout>
                <Companies />
              </SuspenseLayout>
            ),
          },
          {
            path: "/home/users",
            element: <Navigate to="/home/users/users" replace />,
          },
          {
            path: "/home/users/users",
            element: (
              <SuspenseLayout>
                <UserAdmin />
              </SuspenseLayout>
            ),
          },
          {
            path: "/home/users/rols",
            element: (
              <SuspenseLayout>
                <UserAdmin />
              </SuspenseLayout>
            ),
          },
          {
            path: "/home/miscellaneous",
            element: <Navigate to="/home/miscellaneous/marketer" replace />,
          },
          {
            path: "/home/miscellaneous/marketer",
            element: (
              <SuspenseLayout>
                <Miscellaneous />
              </SuspenseLayout>
            ),
          },
          {
            path: "/home/miscellaneous/bank",
            element: (
              <SuspenseLayout>
                <Miscellaneous />
              </SuspenseLayout>
            ),
          },
          {
            path: "/home/miscellaneous/domain",
            element: (
              <SuspenseLayout>
                <Miscellaneous />
              </SuspenseLayout>
            ),
          },
          {
            path: "/home/miscellaneous/payment",
            element: (
              <SuspenseLayout>
                <Miscellaneous />
              </SuspenseLayout>
            ),
          },
          {
            path: "/home/miscellaneous/concepts",
            element: (
              <SuspenseLayout>
                <Miscellaneous />
              </SuspenseLayout>
            ),
          },
          {
            path: "/home/miscellaneous/rates",
            element: (
              <SuspenseLayout>
                <Miscellaneous />
              </SuspenseLayout>
            ),
          },
          {
            path: "/home/miscellaneous/rates/energy",
            element: (
              <SuspenseLayout>
                <Miscellaneous />
              </SuspenseLayout>
            ),
          },
          {
            path: "/home/miscellaneous/rates/water",
            element: (
              <SuspenseLayout>
                <Miscellaneous />
              </SuspenseLayout>
            ),
          },
          {
            path: "/home/miscellaneous/rates/cleanliness",
            element: (
              <SuspenseLayout>
                <Miscellaneous />
              </SuspenseLayout>
            ),
          },
          {
            path: "/home/miscellaneous/rates/street_lighting",
            element: (
              <SuspenseLayout>
                <Miscellaneous />
              </SuspenseLayout>
            ),
          },
          {
            path: "/home/miscellaneous/settings",
            element: (
              <SuspenseLayout>
                <Miscellaneous />
              </SuspenseLayout>
            ),
          },
          {
            path: "/home/downloads",
            element: (
              <SuspenseLayout>
                <Downloads />
              </SuspenseLayout>
            ),
          },
          {
            path: "/home/uploader",
            element: (
              <SuspenseLayout>
                <Uploader />
              </SuspenseLayout>
            ),
          },
          {
            path: "/home/uploader/history",
            element: (
              <SuspenseLayout>
                <Uploader />
              </SuspenseLayout>
            ),
          },
          {
            path: "/home/audit/*",
            element: (
              <SuspenseLayout>
                <AuditPane />
              </SuspenseLayout>
            ),
          },
          {
            path: "/home/bi-reports",
            element: (
              <SuspenseLayout>
                <BIReports />
              </SuspenseLayout>
            ),
          },
          {
            path: "/home/cashsourse",
            element: <Navigate to="/home/cashsourse/transactions" replace />,
          },
          {
            path: "/home/cashsourse/transactions",
            element: (
              <SuspenseLayout>
                <CashTabs />
              </SuspenseLayout>
            ),
          },
          {
            path: "/home/cashsourse/fund",
            element: (
              <SuspenseLayout>
                <CashTabs />
              </SuspenseLayout>
            ),
          },
          {
            path: "/home/cashsourse/cashsource",
            element: (
              <SuspenseLayout>
                <CashTabs />
              </SuspenseLayout>
            ),
          },
          {
            path: "/home/cashsourse/budget",
            element: (
              <SuspenseLayout>
                <CashTabs />
              </SuspenseLayout>
            ),
          },
          {
            path: "/home/cashsourse/pending",
            element: (
              <SuspenseLayout>
                <CashTabs />
              </SuspenseLayout>
            ),
          },
          {
            path: "/home/cashsourse/conciliationsByDay",
            element: (
              <SuspenseLayout>
                <CashTabs />
              </SuspenseLayout>
            ),
          },
          {
            path: "/home/cashsourse/conciliations",
            element: (
              <SuspenseLayout>
                <CashTabs />
              </SuspenseLayout>
            ),
          },
          {
            path: "/home/cashsourse/bankMovements",
            element: (
              <SuspenseLayout>
                <CashTabs />
              </SuspenseLayout>
            ),
          },
          {
            path: "/home/cashsourse/bankMovementsByDay",
            element: (
              <SuspenseLayout>
                <CashTabs />
              </SuspenseLayout>
            ),
          },
          {
            path: "/home/cashsourse/Cashbags",
            element: (
              <SuspenseLayout>
                <CashTabs />
              </SuspenseLayout>
            ),
          },
          {
            path: "/home/cashsourse/movementsandtransfers",
            element: (
              <SuspenseLayout>
                <CashTabs />
              </SuspenseLayout>
            ),
          },
          {
            path: "/home/analysis",
            element: (
              <SuspenseLayout>
                <AdminPoints />
              </SuspenseLayout>
            ),
          },
          {
            path: "/home/statistics",
            element: <Navigate to="/home/statistics/pay" replace />,
          },
          {
            path: "/home/statistics/pay",
            element: (
              <SuspenseLayout>
                <PayStatsModal />
              </SuspenseLayout>
            ),
          },
          {
            path: "/home/statistics/searchbills",
            element: (
              <SuspenseLayout>
                <UploaderStatistics />
              </SuspenseLayout>
            ),
          },
          {
            path: "/home/statistics/overdue",
            element: (
              <SuspenseLayout>
                <PayStatsModal />
              </SuspenseLayout>
            ),
          },
          {
            path: "/home/statistics/unauthorized",
            element: (
              <SuspenseLayout>
                <PayStatsModal />
              </SuspenseLayout>
            ),
          },
          {
            path: "/home/statistics/immediate",
            element: (
              <SuspenseLayout>
                <PayStatsModal />
              </SuspenseLayout>
            ),
          },
          {
            path: "/home/statistics/bydays",
            element: (
              <SuspenseLayout>
                <PayStatsModal />
              </SuspenseLayout>
            ),
          },
          {
            path: "/home/assets",
            element: <Navigate to="/home/assets/loads" replace />,
          },
          {
            path: "/home/assets/loads",
            element: (
              <SuspenseLayout>
                <Assets />
              </SuspenseLayout>
            ),
          },
          {
            path: "/home/assets/meters",
            element: (
              <SuspenseLayout>
                <Assets />
              </SuspenseLayout>
            ),
          },
          {
            path: "/home/vertebots",
            element: <Navigate to="/home/vertebots/bdownloads" replace />,
          },
          {
            path: "/home/vertebots/vbots",
            element: (
              <SuspenseLayout>
                <VertebotsAdmins />
              </SuspenseLayout>
            ),
          },
          {
            path: "/home/vertebots/bdownloads",
            element: (
              <SuspenseLayout>
                <VertebotsAdmins />
              </SuspenseLayout>
            ),
          },
          {
            path: "/home/budgetclient",
            element: (
              <SuspenseLayout>
                <BudgetClient />
              </SuspenseLayout>
            ),
          },
          {
            path: "/home/budgetclient/detail",
            element: (
              <SuspenseLayout>
                <BudgetClient />
              </SuspenseLayout>
            ),
          },
          {
            path: "/home/guruve",
            element: <Navigate to="/home/guruve/users" replace />,
          },
          {
            path: "/home/guruve/users",
            element: (
              <SuspenseLayout>
                <Guruve />
              </SuspenseLayout>
            ),
          },
          {
            path: "/home/guruve/history",
            element: (
              <SuspenseLayout>
                <Guruve />
              </SuspenseLayout>
            ),
          },
          {
            path: "/home/guruve/schedules",
            element: (
              <SuspenseLayout>
                <Guruve />
              </SuspenseLayout>
            ),
          },
          {
            path: "/home/guruve/groups",
            element: (
              <SuspenseLayout>
                <Guruve />
              </SuspenseLayout>
            ),
          },
          {
            path: "/home/guruve/messages",
            element: (
              <SuspenseLayout>
                <Guruve />
              </SuspenseLayout>
            ),
          },
          {
            path: "/home/guruve/interactions",
            element: (
              <SuspenseLayout>
                <Guruve />
              </SuspenseLayout>
            ),
          },
          {
            path: "/home/guruve/templates",
            element: (
              <SuspenseLayout>
                <Guruve />
              </SuspenseLayout>
            ),
          },
          {
            path: "/home/guruve/eguru",
            element: (
              <SuspenseLayout>
                <Guruve />
              </SuspenseLayout>
            ),
          },
          {
            path: "/home/guruve/instances",
            element: (
              <SuspenseLayout>
                <Guruve />
              </SuspenseLayout>
            ),
          },
          {
            path: "/home/ecoeficiencia",
            element: (
              <SuspenseLayout>
                <Ecoeficiencia />
              </SuspenseLayout>
            ),
          },
          {
            path: "/home/reports",
            element: (
              <SuspenseLayout>
                <Reports />
              </SuspenseLayout>
            ),
          },
          {
            path: "/home/claims",
            element: (
              <SuspenseLayout>
                <OdooClaim />
              </SuspenseLayout>
            ),
          },
          {
            path: "/home/fileviewer",
            element: (
              <SuspenseLayout>
                <Fileviewer />
              </SuspenseLayout>
            ),
          },
          {
            path: "/home/claims",
            element: (
              <SuspenseLayout>
                <OdooClaim />
              </SuspenseLayout>
            ),
          },
          {
            path: "/home/emails",
            element: (
              <SuspenseLayout>
                <EmailsTabs />
              </SuspenseLayout>
            ),
          },
          {
            path: "/home/review",
            element: (
              <SuspenseLayout>
                <ReviewBillsTabs />
              </SuspenseLayout>
            ),
          },
        ],
      },
    ],
  },
];
