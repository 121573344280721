import { getTokenBack } from "../utils/auth";
import { onError } from "@apollo/client/link/error";
import {
  split,
  ApolloClient,
  HttpLink,
  InMemoryCache,
  ApolloLink,
  concat,
} from "@apollo/client";
import { getMainDefinition } from "@apollo/client/utilities";
import store from "../store/store";
import { WebSocketLink } from "@apollo/client/link/ws";
import { backenSocketUrl, backendUrl } from "../utils/enviroments";

const INVALID_TOKEN_ERROR: string = "TOKEN DE AUTHORIZACION INVALIDO";

export const createApolloClient = () => {
  const httpLink = new HttpLink({
    uri: backendUrl + "/graphql",
  });

  const wsLink = new WebSocketLink({
    uri: backenSocketUrl + "/graphql",
    options: {
      reconnect: true,
    },
  });

  const authMiddleware = new ApolloLink((operation, forward) => {
    store.dispatch({ type: "globalLoading", payload: true });
    if (
      operation.operationName !== "LOGIN" &&
      operation.operationName !== "RESTOREPASS"
    ) {
      operation.setContext({
        headers: {
          authorization: "Bearer " + getTokenBack(),
        },
      });
    }
    return forward(operation).map((data) => {
      store.dispatch({ type: "globalLoading", payload: false });
      return data;
    });
  });

  const logoutLink = onError((networkError: any) => {
    store.dispatch({ type: "globalLoading", payload: false });
    if (networkError) {
      if (
        networkError.response &&
        networkError.response.errors &&
        networkError.response.errors.some(
          (error: any) => error.message === INVALID_TOKEN_ERROR
        )
      ) {
        localStorage.clear();
        window.location.replace("/login");
      }
    }
  });
  const splitLink = split(
    ({ query }: any) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === "OperationDefinition" &&
        definition.operation === "subscription"
      );
    },
    wsLink,
    httpLink
  );

  return new ApolloClient({
    link: concat(authMiddleware, logoutLink.concat(splitLink)),
    cache: new InMemoryCache(),
  });
};
