{
  "login": "Iniciar sesión",
  "user": "Usuario",
  "password": "Contraseña",
  "enter": "INGRESAR",
  "recover": "Recuperar credenciales",
  "info_card": "Para soporte sobre la aplicación SmartEco por favor enviar un correo a",
  "user_not_valid": "Porfavor ingresa un usuario valido!",
  "user_password_valid": "Por favor ingresa una clave valida",
  "password_size": "La clave debe tener almenos 12 caracteres",
  "recovery_options": "Opciones de recuperación",
  "form": "Formulario",
  "recover_user": "Recuperar usuario",
  "recover_password": "Recuperar contraseña",
  "cancel": "Cancel",
  "label_email": "Correo electronico",
  "send_email": "Enviar correo",
  "recover_password_capital_letter": "RECUPERAR CONTRASEÑA",
  "name_user_capital_letter": "Nombre de usuario",
  "field_required": "Este campo es obligatorio",
  "new_password_capital_letter": "NUEVA CONTRASEÑA",
  "recover_password_text": "Recuerde que la contraseña debe tener al menos 12 caracteres",
  "very_weak": "Muy débil",
  "weak": "Débil",
  "good": "Buena",
  "strong": "Fuerte",
  "verificate_password_capital_letter": "VERIFICAR CONTRASEÑA",
  "update_password": "Change Password",
  "all_rights_reserved": "Todos los derechos reservados",
  "version": "version",
  "updatePassword": "Contraseña Modificada",
  "error_message1": "Oupss ha ocurrido un problema",
  "equal_fields": "Los campos deben coincidir",
  "shortcut": "SHORTCUTS",
  "msgIdiom": "Idioma cambiada correctamente",
  "language": "Language",
  "spanish": "Spanish",
  "english": "English",
  "Portuguese": "Portuguese",
  "SignOff": "Sign off",
  "management": "MANAGEMENT",
  "pane": "tool pane",
  "viewShortcuts": "See keyboard shortcuts",
  "closeShortcuts": "Close keyboard shortcuts",
  "beginning": "Inicio",
  "updateTable": "Actualizar tabla",
  "point": "Points",
  "account": "Accounts",
  "groups": "Groups",
  "domains": "domains",
  "doamin": "domain",
  "rates": "Rate",
  "transactions": "transactions",
  "serviceProvider": "Prestador de servicio",
  "entities": "Entities",
  "paymentMethods": "Payment methods",
  "money": "Money",
  "ResourceSources": "Resource Sources",
  "budget": "Budget",
  "bill": "Bill",
  "Payments": "Pagos",
  "audits": "Auditorías",
  "loads": "Loads",
  "meter": "Meters",
  "search": "Buscador",
  "reportDownloads": "Descargas de reportes",
  "company": "Company",
  "roles": "Roles",
  "invoiceDashboard": "Dashboard facturas",
  "dashboardPayments ": "Dashboard pagos",
  "Vertebots": "Vertebots",
  "serviceProviders": "Service providers",
  "concepts": "Concepts",
  "name": "Nombre",
  "copy": "Copiar",
  "copied": "Copiado",
  "abbreviation": "Abreviación",
  "abb": "Abr",
  "type": "Tipo",
  "identificationBusiness": "NIT",
  "dv": "DV",
  "service": "Servicios",
  "movii": "Movii",
  "voucr": "Comprobante",
  "email": "email",
  "yes": "Si",
  "no": "No",
  "delete": "Eliminar",
  "edit": "Editar",
  "create": "Crear",
  "searchs": "Buscar",
  "state": "Estado",
  "activate": "Activar",
  "deactivate": "Desactivar",
  "activeTable": "Activo",
  "inactive": "Inactivo",
  "subproject": "Subproyecto",
  "extras": "Extras",
  "seeExtras": "Ver Extras",
  "extrasOf": "EXTRAS DE ",
  "addEquivalence": "Agregar equivalencia",
  "department": "Departamento",
  "city": "Ciudad",
  "dateFirst": "Fecha de inicio",
  "dateEnd": "Fecha final",
  "total": "Total",
  "record": "Archivos",
  "detail": "Detalle",
  "remember": "Recuerde",
  "textAlertProvider": "Para poder administrar las notificaciones, la cuenta debe ser editada y seleccionar la opción de multi notificación.",
  "deleteTextAlertProvider": "El prestador de servicio ha sido eliminado con éxito.",
  "upateTextAlertProvider": "Prestador de servicio actualizado a:",
  "updateNotificationProviderActivate": "Envio de notificaciones activada",
  "updateNotificationProviderDesactivate": "Envio de notificaciones desactivada",
  "manageNotifications": "Administrar notificaciones",
  "activatePetitionBill": "Activar Envio de petición de factura",
  "deactivatePetitionBill": "Desactivar Envio de petición de factura",
  "viewProviderInformation": "Ver informacion del prestador",
  "close": "Cerrar",
  "short": "Corto",
  "multiNotification": "MULTI NOTIFICACION",
  "phone": "Teléfono",
  "fieldRequiredNumber": "Este campo solo acepta números",
  "fieldRequiredDv": "Debe digitar el numero de digitación",
  "fielRequiredType": "Debe seleccionar un tipo",
  "typeService": "Tipo de Servicio",
  "fieldRequiredTypeService": "Debe seleccionar el tipo de servicio",
  "proffSeding": "ENVIO DE COMPROBANTE",
  "fieldRequiredOption": "Debe seleccionar una opción",
  "notificationEmail": "Notificación por correo",
  "regularPayment": "PAGO REGULAR",
  "regulatedPayment": "Pago regulado",
  "reactiveCharge": "Cobro por reactiva",
  "fieldNitRequired": "Debe digitar un NIT",
  "alertEntityDelete": "La entidad ha sido eliminado con éxito.",
  "nameEntity": "Nombre de la entidad",
  "stateEnity": "Estado de la entidad",
  "notificationDeleteDomains": "El dominio ha sido eliminado con éxito.",
  "father": "Padre",
  "dNotHave": "No tiene",
  "domainCreate": "Dominio creado",
  "alertDomainCreate": "Su Dominio fue creado con exito",
  "selectionFather": "Debe seleccionar un padre",
  "add": "Agregar",
  "removeFather": "remover padre",
  "alertPaymentDelete": "El metodo de pago ha sido eliminado con éxito.",
  "addEquivalenceA": "Agregar equivalencia a",
  "updateConcept": "Editar concepto",
  "alertDeleteMeter": "Esta seguro que desea borrar este medidor?",
  "deleteMeter": "Medidor eliminado correctamente",
  "updateMeters": "Medidor modificada correctamente",
  "addMeterNews": "Medidor creado correctamente",
  "subprojects": "Subproyectos",
  "disableSubProjectText": "Deshabilitar subproyectos en caso de que no aplique para el tipo deservicio",

  "deleteTarFeeAlert": "Esta seguro que desea borrar esta tarifa?",
  "alertDeleteFee": "Tarifa eliminada correctamente",
  "createRate": "Crear tarifa",
  "updateRate": "Editar tarifa",
  "viewFiles": "Ver Archivo",
  "noFiles": "Sin archivos",
  "voltageLevel": "Nivel de voltaje",
  "propiety": "Propiedad",
  "detailOf": "Detalle de",
  "recolection": "Recolección",
  "publicToilet": "Aseo publico",
  "complementary": "Conplementario",
  "sumptuary": "Suntuario",
  "energeticValue": "Valor Energético",
  "deleteUserQuestion": "Esta seguro que desea borrar este usuario?",
  "deleteUserAlert": "Usuario eliminado correctamente",
  "updateUserQuestion": "Esta seguro que desea cambiar el estado de este usuario?",
  "updateUserAlert": "Estado de usuario modificado",
  "nickName": "Nickname",
  "lastAdmission": "Ultimo ingreso",
  "verified": "Verificado",
  "notVerified": "Verificación pendiente por el usuario",
  "updateState": "Cambiar estado",
  "assign": "Asignar",
  "access": "Accesos",
  "office": "Oficinas",
  "notification": "Notificaciones",
  "download": "Descargar",
  "accessUpdatedAlert": "Accesos actualizados con exito",
  "group": "Group",
  "result": "resultado",
  "userAccessRepeat": "Este usuario tiene todo los grupos asignados",
  "Ops": "Oops",
  "valueRequired": "Este valor es requerido",
  "regionals": "Regional",
  "typeUser": "Tipo de usuario",
  "allRegionals": "Todas las regionales",
  "selectionAll": "Seleccionar todas",
  "deleteAssign": "Borrar asignaciones",
  "textUserAssgin": "Para continuar por favor seleccione una compañía",
  "assignReportBi": "Asignar Reportes de BI",
  "report": "Reportes",
  "all": "Todos",
  "url": "URL",
  "reportDownload": "Reportes de descarga",
  "assignReportDownload": "Asignacion de reportes",
  "alertOfficeRegister": "Oficinas registaradas correctamente",
  "permissions": "Permisos",
  "removed": "Eliminada",
  "saveChanges": "Guarde para salvar los cambios.",
  "save": "Save",
  "alertMessageNotifications": "Todas las notificaciones quedaran desactivadas.",
  "alertConfigNotifications": "Configuraciones de Notificaciones actualizadas",
  "configNotification": "Configurar notificaciones",
  "activateConfirm": "Activada",
  "deactivateConfirm": "Desactivada",
  "messageNotifications": "Se ha desactivado todas las notificaciones de la compañia",
  "notificationBillCompany": "Notificar si la factura de la compañia",
  "wasLoaded": "fue cargada.",
  "notificationVoucherCompany": "Notificar si el comprobante de pago de la compañia",
  "itRejected": "fue rechazada.",
  "notifyIf": "Notificar si",
  "billAndVouchers": "carga factura y/o comprobantes.",
  "addRolSave": "El rol ya fue agregado para esta compañia",
  "userSaveUpdated": "Usuario actualizado correctamente",
  "userGetInto": "Por favor ingresa un nombre!",
  "lastName": "Apellidos",
  "lastNameUsergetInto": "Por favor ingresa un apellido!",
  "emailError": "Porfavor ingresa un correo valido!",
  "rolAplication": "Rol de la applicacion",
  "assignRolCompany": "Asignar roles por compañia",
  "generatePassword": "Generar contraseña",
  "pleasePassworValidate": "Porfavor ingresa una contraseña valida!",
  "users": "Users",
  "rolDeleteAlert": "Esta seguro que desea borrar este rol?",
  "rolDeleteAlertConfirm": "Medidor eliminado correctamente",
  "updateRolAlert": "Esta seguro que desea cambiar el estado de este medidor?",
  "updateRolAlerModif": "Estado de rol modificado",
  "cancelAlert": "Cancelada",
  "actionUpdateCancel": "Acción de cambio de estado cancelada",
  "rolModif": "Rol modificado correctamente",
  "createRol": "Rol creado correctamente",
  "pointDeleteAlert": "El punto ha sido eliminado con éxito.",
  "notVerificed": "",
  "admiPoint": "Manage point",
  "viewInfoPoint": "View point information",
  "viewHistoryPoint": "View point history",
  "generateCertificed": "Generate certificate",
  "order": "Order",
  "direction": "Direction",
  "others": "Others",
  "location": "Location",
  "power": "Power",
  "amount": "Amount",
  "noData": "No data",
  "enterPointName": "You must enter a point name",
  "namePoint": "Point Name",
  "fullName": "Full Name",
  "enterCityName": "You must enter the city name",
  "enterDptoName": "You must enter the department",
  "enterLocationName": "You must enter an address",
  "paymentNotification": "Payment notification",
  "sendNotificationPayment": "Send payment notification to payment",
  "pointState": "Point state",
  "officeCode": "Office Code",
  "entrerCodOffice": "You must enter the office code",
  "numberEmployer": "Number of employees",
  "area": "Area",
  "latitude": "Latitud",
  "fielObligatoryLatitude": "Este campo es obligatorio si coloco una longitud",
  "length": "Longitud",
  "fieldObligatoryLength": "Este campo es obligatorio si coloco una latitud",
  "namePonitShort": "Debe escribir un nombre corto del punto",
  "updateStateExit": "Se cambio el estado de la cuenta con exito",
  "dateFirstOperation": "Fecha de inicio de operación",
  "dateSelection": "Debe seleccionar una fecha",
  "dateUpdateText": "Fecha de cambio de estado",
  "infoPoint": "Informacion del punto",
  "employer": "Empleados",
  "codOffice": "Codigo de oficina",
  "allowsNotifications": "Permite notificaciones",
  "update": "Cambiar",
  "shedule": "Horarios",
  "enterDate": "Ingrese una fecha",
  "enterDateEnd": "Ingrese una fecha final",
  "accountDelete": "La cuenta ha sido eliminado con éxito.",
  "editAuthorizations": "Editar autorizaciones",
  "UploadArchive": "Subir archivo plano enel",
  "addNovelty": "Agregar novedad",
  "viewNovelty": "Ver novedades",
  "viewInfoAccount": "Ver informacion de la cuenta",
  "viewHistoryAcoount": "Ver historico de cuentas",
  "number": "Número",
  "dates": "Fechas",
  "Cleanliness": "Aseo",
  "water": "Agua",
  "enegy": "Energía",
  "Gas": "Gas",
  "sewerage": "Alcantarillado",
  "streetLighting": "Alumbrado público",
  "novelty": "Novedades",
  "authorized": "Autorizado",
  "paymentInfoCompleted": "Info. pago",
  "newComercio": "Nueva comercializadora desde",
  "previusAccount": "Cuenta anterior",
  "inactiveSince": "Inactivo desde",
  "authorizedPayment": "Pago autorizado",
  "dataAccount": "Datos de la cuenta",
  "codVerification": "Codigo de verificación",
  "enterNumberAccount": "Debe escribir un numero de cuenta",
  "numberMeter": "Numero de medidor",
  "emailNotification": "Correo de notificación",
  "markertType": "Tipo de mercado",
  "infoLocation": "Información de ubicación",
  "selectionRegional": "Debe seleccionar la regional",
  "selectionPoint": "Debe seleccionar un punto",
  "selectionService": "Debe seleccionar el prestador de servicio",
  "dateEmition": "Fecha de emisión",
  "dateStimedPayment": "Fecha estimada de pago",
  "timelyPaymentDate": "Fecha pago oprtuno",
  "initialBillingDate": "Fecha inicial de facturación",
  "dateInitial": "Fecha inicial",
  "BudgetsAndPayments": "Presupuestos y pagos",
  "frequency": "Frecuencia",
  "accountFather": "Cuenta padre",
  "socioeconomicLevel": "Nivel socioeconómico",
  "paymentVertebra": "Pago vertebra",
  "authorizedForPayment": "Autorizado para pago",
  "clientUploadBill": "Cliente sube factura",
  "serviceDate": "ServiciosFechas",
  "StreetLighting": "Alumbrado publico",
  "selectionFrecuency": "Debe seleccionar la frecuencia",
  "uploadFileOk": "Carga de archivo plano subida correctamente",
  "dateBill": "Fecha de la factura",
  "dateReal": "Fecha real",
  "billingPeriod": "Periodo de facturación",
  "dateBillDate": "Fecha de facturación",
  "suspencionDate": "Fecha de suspención",
  "period": "Periodo",
  "upSupport": "Subir soporte",
  "fieldRequired": "Campo requerido!",
  "clickUploadVoucher": "Click o arrastre el comprobante",
  "uploadFile": "Subir archivo",
  "changeOfMarketer": "Cambio de comercializadora",
  "numberAccount": "Numero de cuenta",
  "estimatedDateOFIssue": "Fecha estimada de emisión",
  "tymelyPaymentDate": "Fecha pago oportuno",
  "infoPaymentVertebra": "Información pago vertebra",
  "allowPayment": "Permite pago",
  "typeNovelty": "Tipo de novedad",
  "comentary": "Comentarios",
  "observation": "Observación",
  "comentaryNovelty": "Comentario de la novedad",
  "newNovelty": "Crear novedad",
  "shortName": "Nombre corto",
  "allowPaymentNotificatios": "Permite notificaciones de pago",
  "inactivationDate": "Fecha de inactivación",
  "propertyLevel": "Nivel de propiedad",
  "coordinates": "Coordenadas",
  "dateOfIssue": "Fecha de emisión",
  "numberVerification": "Numero de verificacion",
  "serviceName": "Nombre del servicio",
  "lastBill": "Ultima factura",
  "selectRecordTheChange": "Seleccione un registro para ver los cambios",
  "export": "Export",
  "userMakingtheChange": "Usuario que realiza el cambio",
  "operationDate": "Fecha de operación",
  "alertDeleteRates": "Esta seguro que desea borrar esta tarifa?",
  "rateDeleteOk": "Tarifa eliminada correctamente",
  "closeNovelty": "Cerrar novedad",
  "addComentary": "Agregar comentario",
  "deleteGroupAlert": "Grupo eliminado correctamente",
  "groupCode": "Código de grupo",
  "groupName": "Nombre del grupo",
  "errorNameRegional": "Debe escribir un nombre de regional",
  "selectionStateError": "Debe seleccionar un estado",
  "stateGroup": "Estado del grupo",
  "transaction": "transaction",
  "resourceSource": "Origen de recurso",
  "BillToPay": "bills to pay",
  "newTransaction": "Crear transacción",
  "closeTransaction": "Cerrar transacción",
  "concilationsView": "Ver conciliaciones",
  "uploadFileConcilation": "Cargar archivo de conciliación",
  "operationType": "Tipo de operación",
  "bankVerification": "Verificado banco",
  "menu": "Menú",
  "transactionVerified": "Se reestablecio la transacción",
  "amounts": "Monto",
  "initialBalance": "Saldo inicial",
  "endBalance": "Saldo final",
  "responsible": "Responsable",
  "reconcile": "Consiliar",
  "viewInfoBill": "Ver información de la factura",
  "detailBill": "Detalle de Factura",
  "noveltyDeleteOk": "Novedad elimnado con exito",
  "comentaryDeleteOk": "Comentatio elimnado con exito",
  "sourceIdResource": "ID Origen del recurso",
  "sourceNameOfTheresource": "Nombre origen del recurso",
  "sourceIdCompany": "ID Origen de la compañia",
  "sourceNameOfTheCompany": "Nombre origen de la compañia",
  "place": "Lugar",
  "contactAccount": "Cuenta contrato",
  "marketer": "Comercializadora",
  "numberBill": "Numero de factura",
  "typeRegister": "Tipo de registro",
  "totalToPay": "Total a pagar",
  "paymentMade": "Pago realizado",
  "dateOfBills": "Fecha de factura",
  "dateSuspencion": "Fecha de suspension",
  "paymentDate": "Fecha de pago",
  "viewBill": "Ver Factura",
  "updateCompany": "Compañía cambiada correctamente",
  "updateQuuestionCompany": "Esta seguro que desea cambiar el estado de esta compañía?",
  "stateCompanyAlert": "Estado de compañía modificado",
  "assignNotification": "Asignar notificaciones",
  "newCompany": "Crear compañía",
  "editCompany": "Editar compañía",
  "manager": "Manager",
  "change": "Cambiar",
  "updateTransaction": "Editar transacción",
  "reconciliations": "Conciliaciones",
  "manualReconciliations": "Consiliaciones manuales",
  "originOfMoney": "Origen del dinero",
  "paymentMethod": "Metodo de pago",
  "paymentReference": "Referencia de pago",
  "valuePaid": "Valor pagado",
  "voucherNumber": "Numero del comprobante",
  "enablePaymentVertebra": "Habilitado para pago vertebra",
  "enableForPayment": "Habilitado para pago",
  "seeProof": "Ver Comprobante",
  "creation": "Creación",
  "billLoad": "Carga de factura",
  "typedBy": "digitada por",
  "approvedBy": "Aprovada por",
  "voucherUpload": "Carga del comprobante",
  "conceptsForConsumption": "Conceptos por consumo",
  "toiletPrice": "Precio del aseo",
  "contributionForTheToilet": "Contribución por el aseo",
  "totalPaidInToilet": "Total pagado en aseo",
  "activeEnergyCurrentMeasurement": "Medida actual de energía activa",
  "previusEnergyCurrentMeasurement": "Medida anterior de eneriga activa",
  "activePowerEnergy": "Factor de energía activa",
  "activeEnergyConsumption": "Consumo de energía activa",
  "activeEnergyRate": "Tarifa de la energía activa",
  "activeEnergyPrice": "Precio de la energía activa",
  "activeEnergyContribution": "Contribución por energía activa",
  "currentMesurementOrReactiveEnergy": "Medida actual de energía reactiva",
  "previusMesurementOrReactiveEnergy": "Medida anterior de energía reactiva",
  "reactivePowerfactor": "Factor de energía reactiva",
  "reactiveEnergyComsuption": "Consumo por energía reactiva",
  "reactiveEnergyrate": "Tarifa por energía reactiva",
  "reactiveEnergiPrice": "Precio de la energía reactiva",
  "contributionForReactiveEnergy": "Contribución por la energía reactiva",
  "hourOfInterruption": "Horas de interrupción",
  "totalPaidEnergy": "Total pagado en energía",
  "currentMeasureOfAqueduct": "Medida actual de acueducto",
  "previusMeasureOfAqueduct": "Medida anterior de acueducto",
  "waterComsuption": "Consumo de agua",
  "basicAqueductRate": "Tarifa basica acueducto",
  "coplementaryAqueductRate": "Tarifa complementaria acueducto",
  "suntaryAqueductRate": "Tarifa suntuario acueducto",
  "basicFixedPriceAqueduct": "Precio fijo básico acueducto",
  "complementaryFixedPriceAqueduct": "Precio fijo complementario acueducto",
  "fixedPriceQueduct": "Precio fijo acueducto",
  "aqueductContribution": "Contribución acueducto",
  "contributionForAqueductFixedPrice": "Contribución por precio fijo acueducto",
  "basicSewerRate": "Tarifa básica alcantarillado",
  "sumpturySewerRate": "Tarifa suntuaria alcantarillado",
  "basicFIxedPriceAqueduct": "Precio fijo básico acueducto",
  "coplementarySewerRate": "Tarifa complementaria alcantarillado",
  "coplementaryFixedPriceAqueduct": "Precio fijo complementario acueducto",
  "fixedPriceSumptaryAqueduct": "Precio fijo suntuario acueducto",
  "seweragePrice": "Precio del alcantarillado",
  "fixedPriceAqueduct": "Precio fijo acueducto",
  "fixedPriceSewerage": "Precio fijo alcantarillado",
  "contributionForSewerage": "Contribución  por alcantarillado",
  "contributionForFixedSeweragePrice": "Contribución por precio fijo alcantarillado",
  "pricePaidForTheAqueductP": "Precio pagado por el acueducto",
  "pricePaidForTheAqueduct": "Precio pagado por acueducto",
  "totalOther": "Total de otros",
  "voluntaryContribution": "Contribución voluntaria",
  "otherToilet": "Otros Aseo",
  "observations": "Observaciones",
  "noComment": "Sin comentarios",
  "deleteComentaryAlertBill": "Usted va a eliminar un comentario de la factura",
  "notComentary": "No hay comentarios",
  "deleteNoveltyBillAlert": "Usted va a eliminar una novedad de la factura",
  "notNovelty": "No hay novedades",
  "tracing": "Seguimiento",
  "by": "por",
  "at": "a las",
  "sorceId": "Origen ID",
  "source": "Origen",
  "destinityID": "Destino ID",
  "destinity": "Destino",
  "date": "Fecha",
  "reference": "Referencia",
  "transferAmount": "Monto transferido",
  "resource": "Recurso",
  "transactionInfo": "Información de transacción",
  "refrenceInfo": "Información de referencia",
  "operationBillAsiigned": "La operacion no tiene una factura asignada.",
  "paymentInfo": "Información del pago",
  "auditInfo": "Información de auditorías",
  "notNoveltyAdd": "No se han agregado novedades",
  "notConceptsAdd": "No se han agregado conceptos",
  "selectionDate": "Debe seleccionar una fecha",
  "transactioExits": "La transacción ha sido cerrada con éxito",
  "startDateOperation": "Fecha de inicio de operación",
  "errorFileUpload": "error en carga de archivo.",
  "imageSizeErr": "la imagen no debe ser mayor a 20MB",
  "transactionEditOk": "Transacción editada correctamente",
  "transactionNewOk": "Transación creada correctamente",
  "nameOperation": "Nombre de la operación",
  "numberBillEnter": "Digite el numero de la factura",
  "numberReferencia": "Numero de referencia",
  "lockedReturn": "Bloquear retroceder",
  "selectionRatesFile": "Click o arrastre el comprobante de la transacción",
  "uploadVaucher": "Cargar comprobantes",
  "uploadFileBIll": "Debe subir una image de la factura",
  "editRates": "Editar transacciones",
  "newRates": "Crear transaccion",
  "returnRates": "Retroceder transacción",
  "fileUploadOk": "Archivo subido con exito",
  "uploadReconciliation": "Subir conciliación",
  "reportNewOk": "Reporte creado con exito",
  "yourReporNewOk": "Su reporte fue creado con exito",
  "manualReconciliation": "Reconciliación manual",
  "recolitianOk": "Reconciliacion realizada con exito",
  "totallySelection": "Total seleccionado",
  "reconconciliationManully": "Realizar conciliacion manual",
  "successConsoliditedOperation": "Operación consolidada correctamente",
  "send": "Enviar",
  "addFounds": "Agregar fondos",
  "editFounds": "Editar fondos",
  "updateQuestion": "¿Esta seguro que desea cambiar el estado?",
  "stateResourseOrigen": "Estado del origen de recurso",
  "balance": "Saldo",
  "incomeMonth": "Ingresos mes",
  "expensesMonth": "Egreso mes",
  "seeDetails": "Ver detalle",
  "viewBalanceHistory": "Ver historial de balances",
  "foundAddSuccefully": "Fondo agregado correctamente",
  "balances": "Balance",
  "uploadProof": "Subir comprobantes",
  "proodUploadFile": "Click o arrastre el comprbante de la transacción",
  "detailRates": "Detalle de una transacción",
  "lastClosing": "Último cierre",
  "newResourceSource": "Crear origen de recurso",
  "editResourceSource": "Editar origen de recurso",
  "moveFounds": "Mover fondos",
  "resourceWithoutNumber": "Origen de recurso sin número",
  "toAccept": "Aceptar",
  "deleteResourceSourceAlert": "Esta seguro que desea borrar este origen de recursos?",
  "resourceSourceDeleteOk": "Origen de recursos eliminado correctamente",
  "styateResourceSourceUpdate": "Estado del origen de recurso cambiado correctamente",
  "assignResourceSource": "Asignacion de origen de recursos",
  "pricipally": "Principal",
  "children": "Hijos",
  "income": "Ingresos",
  "expenses": "Egresos",
  "alert": "Alertas",
  "entitie": "Entidad",
  "assignAccess": "Asignar Accesos",
  "sourceResourceOk": "Origen del recurso creado correctamente",
  "resourceHierachy": "Jerarquia del recurso",
  "foundTransfer": "Fondo transferido correctamente",
  "originResource": "Origen del recurso",
  "transactionDetail": "Detalle de transacción",
  "region": "Región",
  "reg": "Reg",
  "janury": "Enero",
  "jan": "Ene",
  "febraury": "Febrero",
  "feb": "Feb",
  "march": "Marzo",
  "mar": "Mar",
  "april": "Abril",
  "apr": "Abr",
  "May": "Mayo",
  "mays": "May",
  "june": "Junio",
  "july": "Julio",
  "jun": "Jun",
  "jul": "Jul",
  "august": "Agosto",
  "aug": "Ago",
  "september": "Septiembre",
  "sep": "Sep",
  "october": "Octubre",
  "oct": "Oct",
  "november": "Noviembre",
  "nov": "Nov",
  "december": "Diciembre",
  "dec": "Dic",
  "general": "General",
  "gas": "Gas",
  "admin": "Admin",
  "tFija": "T. Fija",
  "tMovil": "T. Movil",
  "tv": "Televisión",
  "stateAccount": "Estado de cuenta",
  "supplier": "Proveedor",
  "restoreInvoice": "Restaurar factura",
  "seeInvoiceAndReceipt": "Ver factura y comprobantes",
  "uploadfBill": "Cargar factura",
  "unpaidInvoiceForVertebra": "Factura no pagada por vertebra",
  "NotDataViews": "No existen datos para mostrar",
  "accountPointBlock": "Cuenta o punto bloqueados",
  "retoreInvoicePeding": "Restaurar factura a pendiente",
  "restore": "Restaurar",
  "support": "Soporte",
  "forPayment": "Por pagar",
  "tracklingUpdateOk": "Seguimiento actualizado correctamente",
  "noveltyPayment": "Novedades de pagos",
  "follows": "Seguimientos",
  "yourRemoveBillFollow": "Usted va a eliminar un seguimiento de la factura",
  "revised": "Revisada",
  "revisedAccount": "Cuenta revisada",
  "billUpdatedAlertPunding": "Esta seguro que desea restaurar esta factura a PENDIENTE?",
  "areYouSure": "Estas seguro?",
  "billUpdateAlertFingered": "Usted va a cambiar el estado de su factura a DIGITADA",
  "paymentNotAuthorized": "Pago no autorizado",
  "month": "Mes",
  "editDateAssing": "Editar fecha asignada",
  "statePaynment": "Estado de pago",
  "plataformNotRegister": "No registrado en plataforma",
  "stateFingered": "Estado en digitacion",
  "companyes": "Compañías",
  "statics": "Estadisticas",
  "rejectBillAlert": "Esta seguro que desea restaurar el factura rechazada?",
  "billRestoredOk": "Factura restaurada correctamente",
  "budgetaryAddAccount": "Esta seguro que desea agregar esta cuenta presupuestaria?",
  "accountAddOk": "Cuenta agregada correctamente al presupuesto",
  "isSecureBill": "Esta seguro que desea revisar esta factura?",
  "accountRevised": "Cuenta revisadas",
  "isSecurePaymenChildren": "Esta seguro que desea propagar el pago en las facturas hijas?",
  "paymentOkAlert": "Pago propagado correctamente",
  "viewVoucher": "Ver Comprobante",
  "reAsign": "Reasignar",
  "addNoveltyAccount": "Agregar novedad de cuenta",
  "restoredRechazed": "Restaurar Rechazada",
  "addBudget": "Agregar a presupuesto",
  "changeBudget": "Change budget",
  "Outof_budget": "Out of budget",
  "autorizedPayment": "Autorizar pago",
  "addNewInvoce": "Agregar Novedad de factura",
  "addNoveltyToTracking:": "Agregar Novedad al seguimiento",
  "slopes": "Pendientes",
  "totallyPayment": "Total a pagar",
  "paid": "Pagadas",
  "totalPaid": "Total pagado",
  "assigns": "Asignado",
  "infoBill": "Informacion de factura",
  "billPendient": "Facturas Pendientes",
  "amountPendient": "Monto Pendientes",
  "porcetain": "Porcentaje",
  "rulesAmount": "Reglas Monto",
  "providers": "Prestadores",
  "new": "Nuevo",
  "assignMissing": "Asignar Faltantes",
  "redistribute": "Redistribuir",
  "uploadChart": "Cargando la gráfica",
  "updateDate": "Se han actualizado los datos.",
  "and": "y",
  "ejectBoots": "Ejecución de bots",
  "paymentMarketerService": "P. Servicio mas pagados",
  "digitBillAlerts": "Digitacion, Facturas Digitadas Alertas",
  "energyConsumerd": "Energía consumida",
  "lastUpdated": "Última vez actualizado:",
  "value": "Valor",
  "status": "Status",
  "vertebots": "Vertebots",
  "downloadBill": "Invoice downloads",
  "infovertebots": "Información de vertebots",
  "ejecstBoots": "Ejecutar vertebots",
  "idFather": "Id Padre",
  "idOFCompany": "Id de Compañia",
  "totalFather": "Total Padre",
  "noStartDate": "Sin fecha de inicio",
  "noEndDate": "Sin fecha de finalización",
  "completed": "Completadas",
  "mistake": "Errores",
  "viewDetailOperation": "Ver Detalle operación",
  "displayInformationAbout": "Desplegar información de",
  "hideInformationFrom": "Ocultar información de",
  "displayLogOf": "Desplegar log de",
  "hideLogFrom": "Ocultar log de",
  "uploadedSuccessfully": "subido correctamente",
  "whenTryingAlert": "al tratar de subir el archivo intente de nuevamente error",
  "vertebotStartOk": "Vertebot iniciado con exito",
  "ocrEjectOk": "OCR ejeutado con exito",
  "oneMeter": "Un medidor",
  "allMeter": "Todos los medidores",
  "youMustUploaderFile": "Debe subir un archivo",
  "clickUploadFile": "Click o arrastre el archivo",
  "selectionDateMeter": " Seleccionar una fecha",
  "selectionDateDownloadBullAndAccount": "Seleccionar una fecha para descargar todas las facturas de una cuenta",
  "treeDownload": "Arbol de descarga",
  "attempts": "Intentos",
  "noBillingDate": "Sin fecha de facturación",
  "lastDateAttempts": "Fecha Ultima intento",
  "noDateAttemptsColumns": "No tiene fecha de ultimo intento",
  "datUpdate": "Fecha de actualización",
  "notDateUpdate": "Sin Fecha de actualización",
  "dateNew": "Fecha de creación",
  "notDateNew": "Sin Fecha de creación",
  "selectionOne": "Seleccione uno",
  "selectionOptionViews": "Seleccione una opción para visualizar los tableros de consumos",
  "rememberOption": "Recuerde que esta opción puede tardar un poco",
  "newLoad": "Crear carga",
  "configurateAvanzedLoad": "Configuración avanzada de carga para medidor tipo",
  "updatesMeters": "EditarMedidor",
  "stateMeterUpdate": "Estado de medidor modificado",
  "actionStateCanceled": "Acción de cambio de estado cancelada",
  "infoLoad": "Información de carga",
  "createAlertMeter": "Crear alertas de medidor",
  "description": "Descripción",
  "meteer": "Medidor",
  "typeMeter": "Tipo de medidor",
  "viewUrl": "Ver URL",
  "configAvanzed": "Conf. Avanzada",
  "viewDetail": "View detail",
  "loadUpdateOk": "Carga modificada correctamente",
  "newLoadOk": "Carga creada correctamente",
  "meterss": "Medidores",
  "inserDescriptionLoad": "Ingrese una descripción de la carga...",
  "uploadSupport": "Subir soporte",
  "textRepeatVoucherUpload": "Ya existe un comprobante cargado, en caso de subir uno nuevo se eliminará el comprobante existente.",
  "instalation": "Instalador",
  "multiplicationFactor": "Factor multiplicación",
  "relationCTS": "Relación CTS",
  "typeOfMeasure": "Tipo de medida",
  "circuitType": "Tipo de circuito",
  "powerFactor": "Factor potencia",
  "voltage": "Voltaje",
  "createAlert": "Crear alerta",
  "editAlert": "Editar alerta",
  "alertDeleteAlert": "Esta seguro que desea borrar esta alerta?",
  "alertDeteleOk": "Alerta eliminado correctamente",
  "var": "Variable",
  "temp": "Tiempo",
  "alertUpodateOk": "Alerta modificada correctamente",
  "alertNewOk": "Alerta creada correctamente",
  "pleaseInsertVar": "Por favor ingresa una variable!",
  "pleaseInsertValue": "Por favor ingresa un valor",
  "pleaseInsertTemp": "Por favor ingresa un tiempo valido",
  "nameOfCompany": "Nombre de compañia",
  "idGroup": "ID De grupo",
  "nameOfGroup": "Nombre de grupo",
  "idPoint": "ID De punto",
  "nameOfPoint": "Nombre del punto",
  "idMeter": "ID De medidor",
  "nameMeter": "Nombre del medidor",
  "viewResource": "Ver Recurso",
  "idOfLoad": "ID De la carga",
  "nameOfLoad": "Nombre de la carga",
  "infoAvanzedLoad": "Información avanzada de la carga",
  "operationInfo": "La operacion no tiene una información",
  "newMeter": "Crear medidor",
  "avanzedConfig": "Configuración avanzada",
  "ediMeter": "Editar medidor",
  "infoMeter": "Información de medidor",
  "updatePasswordMeter": "Cambiar contraseña de medidores",
  "serial": "Serial",
  "protocol": "Protocolo",
  "dateInstalation": "Fecha de Instalación",
  "lastReport": "Último Reporte",
  "serialOfmMeter": "Serial del medidor",
  "deviceName": "Nombre del dispositivo",
  "errorAddinfo": "Error al agregar la información",
  "codNameLastAdd": "El codigo o el nombre de la base de datos ya fueron agregados",
  "NameBDLastAdd": "El nombre de la base de datos ya fue agregado",
  "addProtocolMod": "Adicionar protocolos modbus",
  "addHeader": "Adicionar cabeceras",
  "dataAcquisition": "Adquisición de Datos",
  "frecuencyUnit": "Unidad de frecuencia",
  "frecuencyValue": "Valor de frecuencia",
  "configHeader": "Configuración cabecera",
  "addConfigHeader": "Agregar configuración de cabecera",
  "nameHeader": "Nombre de cabecera",
  "nameBD": "Nombre BD",
  "action": "Acción",
  "directionIp": "Dirección IP",
  "port": "Puerto",
  "protocolModbus": "Protocolo modbus",
  "addProtocol": "Agregar Protocol",
  "register": "Registro",
  "dataLength": "Longitud de dato",
  "tData": "T. Dato",
  "typeData": "Tipo de dato",
  "unit": "Unidad",
  "concentrator": "Concentrador",
  "sim": "Sim",
  "simNumber": "Numero de sim",
  "subType": "Sub tipo",
  "nameOfHeader": "Nombre de cabecera",
  "idConcentrator": "ID De concentrador",
  "adquitionData": "Adquisición de la data",
  "infoOfMeter": "Información del medidor",
  "infoAvanzedMeter": "Información avanzada del medidor",
  "configModBus": "Configuración ModBUS",
  "notInfoOperation": "La operacion no tiene una información.",
  "operation": "Operación",
  "typeEmail": "Tipo de Correo",
  "detailAudit": "Detalle de Auditoría",
  "fileNoXlsx": "El archivo no es xlsx",
  "fileNoZip": "El archivo no es zip",
  "uploader": "Uploader",
  "selectEntitie": "Debe seleccionar una entidad",
  "selectAction": "Debe seleccionar una acción",
  "downloadExample": "Descargar Ejemplo",
  "selectionFile": "Debe seleccionar una archivo",
  "selectFile": "Seleccionar archivo",
  "selectOneField": "Debe seleccionar al menos un campo",
  "selectMergeUnique": "Seleccione combinación única...",
  "stringZero": "Tomar vacíos como String vacío",
  "selectionMethods": "Seleccione método",
  "firstProcess": "Iniciar Proceso",
  "History": "Historial",
  "entiti": "Entidad",
  "process": "Process",
  "mergeUnique": "Combinación Única",
  "treatmentUpdating": "Tratamiento al actualizar",
  "aprovved": "Aprobado",
  "isSecureQuestion": "¿Esta seguro?",
  "generateReport": "Generar reporte",
  "dateOfReport": "Fecha del reporte",
  "selectionYear": "Debe seleccionar el año",
  "criteryDownload": "Criterio de descarga",
  "selectionCritery": "Debe seleccionar un criterio",
  "criteryEDownload": "Criterio a descargar",
  "allGroups": "Todos los grupos",
  "allCompanies": "All companies",
  "selectionAllGroup": "Debe seleccionar al menos un grupo",
  "AllGroupsWereSelected": "Se eligieron todos los grupos",
  "AllCompaniesWereSelected": "All companies were selected",
  "reportCreateExit": "Reporte creado con exito",
  "selectionMonth": "Debe seleccionar el mes",
  "selectionDates": "Seleccione una fecha",
  "allPoint": "Todos los puntos",
  "selectionOnePoint": "Debe seleccionar al menos un punto",
  "selectioAllPoint": "Se eligieron todos los puntos",
  "selectionMeter": "Debe seleccionar al menos un medidor",
  "AllMeterSelection": "Se eligieron todos los medidores",
  "inserDateValidate": "Debe ingresar una fecha valida",
  "typesServices": "Tipos de servicio",
  "selectionOneService": "Debe seleccionar al menos un servicio",
  "selectionAllService": "Se eligieron todos los servicios",
  "selectionOneDigitation": "Debe seleccionar al menos un digitador",
  "selectionAllType": "Se eligieron todos los tipos",
  "creates": "Creado",
  "periode": "Periodo",
  "payment": "Pagado",
  "dateUpload": "Fecha de carga",
  "dateAssing": "Fecha asignada",
  "dateExpedition": "Fecha de Expedición",
  "selectionOneRegional": "Debe seleccionar al menos una regional",
  "options": "Opciones",
  "consumedMonth": "Consumo mensual",
  "separateCleanLight": "Separa aseo",
  "viewsSubProject": "Mostrar Subproyecto",
  "toChooseAllMarketer": "Se eligieron todas las comercializadoras",
  "firstDateReport": "Primera fecha del reporte",
  "secondDateReport": "Segunda fecha del reporte",
  "yearAndMonth": "Año y mes",
  "allServices": "Todos los servicios",
  "ConvertValuePositive": "Convertir a Valores Positivos",
  "logSistem": "Log Sistema",
  "newVersion": "Nueva versión",
  "allAccount": "Todas las cuentas",
  "selectionOneAccount": "Debe seleccionar al menos una cuenta",
  "allsAccount": "Se eligieron todas las cuentas",
  "reportCreaoteOkAlert": "Reporte creada correctamente",
  "selectionOneGroup": "Debe seleccionar un grupo",
  "monthReport": "Mes del reporte",
  "load": "Carga",
  "preview": "Previsualizar",
  "engrave": "Grabar",
  "budgetAvailible": "No hay presupuestos disponibles",
  "check": "Revisar",
  "payBill": "Pagar factura",
  "reviewBill": "Revisar factura",
  "changePayment": "Cambiar Pago",
  "spradPaymentAccount": "Propagar pago de cuenta",
  "viewInfo": "Ver Información",
  "createNoveltys": "Crear Novedades",
  "staticsBill": "Estadisticas de Facturas",
  "selectionBudgetAddBill": "Seleccione un presupuesto para agregar a la factura",
  "config": "Configuración",
  "accountRestore": "Cuenta restaurada",
  "accountGirl": "Cuenta hija",
  "outOfBudget": "Fuera del presupuesto",
  "NotPlataformRegister": "No registrado en plataforma",
  "expiration": "Vencimiento",
  "suspend": "Suspensión",
  "due": "Due",
  "notBNudget": "No budget",
  "dataPayment": "Payment data",
  "delet": "Delete",
  "TheFileDelete": "The file has been deleted",
  "recordUploadFile": "Remember that a new invoice will be uploaded, indicating that more than one invoice has arrived for this month",
  "continue": "continue",
  "newUploabill": "Upload a new invoice",
  "newUpdateBill": "Change Invoice",
  "alertRejectBill": "Remember that rejected invoices will be completely deleted",
  "rejectBill": "Reject invoice",
  "deleteBill": "Delete invoice",
  "deleteVoucher": "Delete voucher",
  "dateBillPrompt": "Prompt payment date",
  "NotObservations": "No observations",
  "infoBillOf": "Invoice information of",
  "obsOfTheBill": "Invoice observations",
  "noveltyOfTheBill": "Invoice novelties",
  "followBill": "Invoice follow-ups",
  "conceptTheBill": "Invoice concepts",
  "moneyBags": "Money bags",
  "phones": "Phones",
  "mobilePhone": "Mobile phone",
  "day": "Day",
  "selection": "Select",
  "obs": "Observation",
  "selections": "Selected",
  "recharge": "Recharge",
  "aPublic": "Public",
  "accountInactive": "Inactive account",
  "pointInactive": "Inactive point",
  "accountAndPointInactive": "Account and point inactive",
  "viewAdmiPoint": "View Admin. Points",
  "notInvoiced": "Not invoiced",
  "configUpdateNotification": "Notification configuration updated",
  "configTextNotification": "Notifications configuration of",
  "notificarEi": "Notify to",
  "whenUploadBill": "when an invoice is uploaded",
  "whenUploadVoucher": "when a voucher is uploaded",
  "whenRejectBill": "when an invoice is rejected",
  "whenSendPetitionBillMarketer": "invoice request by email",
  "notificationTypeUser": "Notify to user type",
  "managers": "Manager",
  "payer": "Payer",
  "client": "Client",
  "loa": "Load",
  "notTypeUser": "No user type",
  "petitionBillAcoount": "Invoice request to account",
  "companyUpdateOk": "Company updated successfully",
  "companyNewOk": "Company created successfully",
  "pleaseInserrNameValid": "Please insert a valid name!",
  "pleaseInsertTypeValid": "Please insert a valid type!",
  "updateStateAccount": "Change account status",
  "auditOfPoint": "Point audits",
  "updateStatePointAsk": "Are you sure you want to change the status of this point?",
  "stateOfPointUpdate": "Point status modified",
  "verificedStanBY": "Standby verification",
  "correo": "Mail",
  "stateOfPoint": "Point status",
  "exportListUpdate": "Export change listings",
  "permitNotificationPayment": "Permit payment notifications",
  "authorizedPaymet": "Authorized payment",
  "lvlSocio": "Socioeconomic level",
  "lvlVoltage": "Voltage level",
  "userRealizedUpdate": "User who made the change",
  "notDateOfOperation": "No operation date",
  "noveltyAccount": "Account novelties",
  "insertFilePlaneEnel": "Upload parent file of Enel",
  "updateStateofAccount": "Change account status",
  "IsSecureUpodateStateAccountAsk": "Are you sure you want to change the status of this account?",
  "stateAcconuntModif": "Account status modified",
  "auditAccountNumber": "Account audit number",
  "ofPoint": "of point",
  "pServicio": "P. Service",
  "dateTI": "Dates T - I",
  "accountFathers": "Father account",
  "idNeuAccount": "New account ID",
  "newNameOfAccount": "New account name",
  "newMarketerService": "New service provider",
  "updateRealizedFor": "change made by",
  "lastMarketer": "previous marketer",
  "updateMarketerForUser": "The user changed the marketer",
  "he": "he",
  "eiTheMarketer": "the marketer",
  "withAccountNumber": "with account number",
  "notUpdateMarketer": "The marketer was not changed",
  "dateTimelyPayment": "Timely payment date",
  "dateInitialOfBilling": "Initial billing date",
  "paymentAuthorized": "Payment authorized",
  "authorizedForPaiment": "Authorized for payment",
  "dateServices": "Services dates",
  "NumberCuentaEnergy": "Energy account number",
  "numberAccountOfStreetLight": "Public A. account number",
  "numberAccountWater": "Water account number",
  "numberAccountOfSewerage": "Sewerage account number",
  "numberAccountCleanlines": "Cleaning account number",
  "numberAccountGas": "Gas account number",
  "voltageLvl": "Voltage level",
  "infoOdfTheAccount": "Account information",
  "editNovelty": "Edit novelties",
  "theGroupDeleteOk": "The group has been deleted successfully",
  "codOfgroup": "Group code",
  "notName": "No name",
  "dateUploadOfBill": "Date of invoice upload",
  "dateOfPaymentBill": "Payment date",
  "back": "Back",
  "companys": "Companies",
  "nameOftheTransaction": "Transaction name",
  "balanceFirst": "Initial balance",
  "balanceEnd": "Final balance",
  "clickFileRates": "Click or drag the transaction file",
  "manualReconciliationss": "Manual reconciliation",
  "reconciliation": "Reconciled",
  "notReconciliation": "Not reconciled",
  "notDate": "No date",
  "notReconciliatioons": "Not reconciled",
  "operationConsolidedOk": "Operation consolidated successfully",
  "historyBalances": "Balance history",
  "uploadProofs": "Upload proofs",
  "lastClose": "Last close",
  "dateNotOfFirst": "Start date",
  "createResouceSource": "Create resource source",
  "sourceResourceNotNumber": "Resource source without number",
  "states": "States",
  "sorceResourceOk": "Resource source correctly",
  "budgetCalculation": "Budget calculation",
  "gestionBudget": "Budget management",
  "isSecurteDeleteBudgetAlertAsk": "Are you sure you want to delete this budget?",
  "budgetDeleteOk": "Budget deleted successfully",
  "isSecureAskAprovedBudget": "Are you sure you want to approve this budget?",
  "theBudgetisAproved": "The budget",
  "isSecureDuplicateBudgetAsk": "Are you sure you want to duplicate this budget",
  "budgetDuplicateOk": "Budget duplicated successfully",
  "selectAQuoteToSync": "Select a budget to sync",
  "budgetCreateOk": "Budget created successfully",
  "note": "Note",
  "textBudgetOne": "The accounts used for budget calculation are those authorized for payment. If the invoices have not arrived and have not been digitized, the approximate payment date and approximate payment amount will be used.",
  "generateBuget": "Generate Budget",
  "dateCreate": "Creation date",
  "view": "View",
  "aprovedQuote": "Approve budget",
  "downloadDetail": "Download Detail",
  "duplicateQuote": "Duplicate budget",
  "syncBudget": "Sync budget",
  "budgetToSyncOk": "Budget synchronized successfully",
  "accounrAddOk": "Account added successfully",
  "isPaymentregisterBudget": "This payment is already registered in the budget",
  "budgetUpdateOk": "Budget modified successfully",
  "isSecureUpdateQuotes": "Are you sure you want to change the status of the budget",
  "budgetSaveOk": "Budget saved successfully",
  "addAccountBudget": "Add account to budget",
  "filterBills": "Filter Invoices",
  "lastBills": "Last Invoice",
  "codeOffice": "Office Code",
  "createRole": "Create role",
  "editRole": "Edit role",
  "notsverificied": "Not Verified",
  "notSendNotificationsAlert": "Notification sending has been deactivated",
  "sendNotificationsAlert": "Notification sending has been activated",
  "petitionBillDesactivate": "Invoice Request Deactivated",
  "petitionBillActivate": "Invoice Request Activated",
  "codMovii": "Movii Code",
  "digitVerification": "Verification digit",
  "insertNumberDigitation": "You must enter the verification number",
  "modo": "Mode",
  "notificationDelete": "Notification deleted successfully",
  "deleteNotificationAlerQuestion": "Are you sure you want to delete this notification?",
  "createNotification": "Create notification",
  "editNotification": "Edit notification",
  "updateNotificationAlert": "Notification modified successfully",
  "notificationCreateOk": "Notification created successfully",
  "petitionBill": "Invoice request to",
  "createConcepts": "Create concept",
  "subService": "Sub Service",
  "textOneCreateConcetp": "Disable subprojects if it does not apply to the service type",
  "equevalenceExist": "This equivalence already exists",
  "equivalence": "Equivalence",
  "atMenosEquivalence": "At least one equivalence",
  "fieldObligatoeyValue": "This field is mandatory, please enter a value",
  "nameOfEquevalence": "Equivalence name",
  "addNewqEquivalence": "Add a new equivalence",
  "saveEquievalence": "Save equivalence",
  "saveUpdate": "Save changes",
  "confir": "Confirm",
  "configs": "Settings",
  "audit": "Audit",
  "odoo": "odoo",
  "emailss": "Post",
  "psERVICE": "P. de Servicio",
  "notCity": "Sin ciudad",
  "notdeparment": "Sin departamento",
  "notPropieruty": "Sin propiedad",
  "notLvlVariedade": "Sin nivel de tensión",
  "rateOptional": "Tarifa opcional",
  "basic": "basico",
  "valueFije": "Valor Fijo",
  "saldeMinime": "Salarios Minimos",
  "ratesUpdateOk": "Tarifa modificada correctamente",
  "ratesOkCreate": "Tarifa creada correctamente",
  "generates": "Generación",
  "tranmition": "Transmisión",
  "distribution": "Distribución",
  "comercialization": "Comercialización",
  "retriction": "Restricciones",
  "lossesAndReduction": "Pérdidas y reducciones",
  "valueEnergy": "Valor de energía",
  "optionTarida": "Opción tarifaria",
  "valueOption": "Valor opcional",
  "textRatesOne": "A file is already uploaded, uploading a new one will delete the previous one",
  "clickFileRatess": "Click or drag the rate file",
  "createSetting": "Create setting",
  "editSetting": "Edit setting",
  "isSecureDeleteSetting": "Are you sure you want to delete this setting?",
  "settingDeleteOk": "Setting deleted successfully",
  "valueColumns": "Values",
  "ValueOf": "Values of ",
  "viewValues": "View values",
  "table": "Tables",
  "tableSyncro": "Synchronized Table",
  "syncro": "Sync",
  "viewss": "View",
  "detailOdoo": "Odoo Detail",
  "displayInformation": "Display Information",
  "ocultInfo": "Hide Information",
  "calculatorAmbiental": "Environmental Calculator",
  "editPoint": "Edit Point",
  "isSecureDeleteElement": "Are you sure you want to delete this element?",
  "elementDeleteOk": "Element deleted successfully",
  "iluminationActivate": "Illumination inversion activated",
  "notIluminationDeactivate": "No illumination inversion",
  "pointVisit": "Point Visited",
  "notPointVisit": "Point visit deactivated",
  "aireVisit": "Air conditioning inversion activated",
  "notAireVisit": "No air conditioning inversion",
  "exportMultiPoint": "Export MultiPoints",
  "iluminations": "Illumination inversion",
  "aire": "Air Conditioning Inversion",
  "visit": "Visited",
  "deleteOeditOne": "You can only delete or edit one at a time",
  "downloadReport": "Download Report",
  "typeOffice": "Office Type",
  "numbersEmployer": "Employee Number",
  "activa": "Active",
  "reactiva": "Reactive",
  "light": "Lighting",
  "acueduct": "Aqueduct",
  "numberAccounts": "Account Number",
  "numberMeters": "Meter Number",
  "shedules": "Schedule",
  "element": "Element",
  "photo": "Photos",
  "elementAddOk": "Element added successfully",
  "assingGestor": "Assign manager users",
  "userAddOk": "Users added successfully",
  "userDeleteOk": "Users deleted successfully",
  "sinAudit": "No audit",
  "auditPayment": "Payment audit",
  "useraddgestor": "Users added to the point successfully",
  "userDeletedGestor": "Users removed from the point successfully",
  "deleteUser": "Delete user",
  "assignUsers": "Assign user",
  "adimin": "Administration",
  "totallyBillAnual": "Total Annual Bill",
  "totallyBillMonth": "Total Monthly Bill",
  "billSinBill": "Bills without upload",
  "billMonth": "Monthly frequency bill",
  "billDigitid": "Digitized bills",
  "billFrequencyMontly": "Bimonthly frequency bill",
  "billPedientDigit": "Pending bills for digitization",
  "billPendie": "Pending bills",
  "billFrequencyTri": "Quarterly frequency bill",
  "billPayment": "Paid bills",
  "billFrequencyAnual": "Annual frequency bill",
  "billReject": "Rejected bills",
  "formule": "Formula",
  "changefrecuency": "Change month frecuency",
  "updloadbillmethod": "Upload bill method",
  "makechange": "Cambio realizado con exito",
  "incumbent_marketer": "Incumbent provider",
  "emailverification": "Verificar correo",
  "removeemailnotification": "Quitar verificación a correo",
  "executed_mode": "Executed budget",
  "marketer_user_type": "User type",
  "applyfilters": "Applied filters",
  "supervisor": "Supervise payment visualization",
  "companies": "Companies",
  "pay_regionals": "Groups",
  "types_pay": "Types",
  "pays_marketers": "Providers",
  "asignee": "Payer",
  "payorder": "Order",
  "filter": "Filter",
  "supervisortag": "Supervisor",
  "issue": "Issue Date",
  "reviewModal": "Invoice review",
  "review": "Review",
  "reviewsubtype": "Sub type of review",
  "approvepayment": "Approved for payment",
  "removereview": "Remove review",
  "restorereview": "Update review",
  "payappropve": "Approved for pay",
  "approved_payment": "Approved for payment",
  "digitation_status_filter": "Digitizing status",
  "datebill": "Billing start date",
  "estimate_issue_date": "estimated date of issue",
  "budget_apply": "Budget apply",
  "active": "Active",
  "revision_status": "Revision status",
  "services_config": "Service configuration",
  "services_message": "You must choose a service",
  "update_settings": "Settings update",
  "uploadBill": "Load bill",
  "client_upload_bill": "Client upload bill",
  "charger": "Loader",
  "odoo_alert": "Generate odoo alerts",
  "update_bill": "Update bill",
  "consumption_matrix": "Matrix",
  "fat_file": "Fat file",
  "file": "File",
  "conciliations": "Conciliations",
  "movementsandtransfers": "Movements and transfers",
  "created_at": "Created at",
  "voucher_number": "Voucher number",
  "date_paid": "Date paid",
  "user_created": "User created",
  "target": "Target",
  "payment_date": "Payment date",
  "date_bill": "Date bill",
  "real_payment": "Total",
  "paid_date": "Paid date",
  "regional_name": "Regional",
  "number_bill": "Number bill",
  "payment_reference": "Payment reference",
  "cash_source_name": "Cash source",
  "account_name": "Account number",
  "payment_method_marketer_name": "Payment method",
  "is_consolidated_true": "Conciliated",
  "is_consolidated_false": "Not conciliated",
  "payment_tax": "Tax",
  "rollback": "Rollback",
  "user_uploaded_voucher": "Payer user",
  "consolidated": "Consolidated",
  "bill_reviewed": "Rewiew",
  "bill_load": "Log",
  "not_consolidated": "Not Consolidated",
  "property_level": "Property Level",
  "voltaje_level": "Voltage level",
  "phone_number": "Phone Number",
  "guru_groups": "Guruve Groups",
  "clear_selections": "Clear selected",
  "places": "Places",
  "max_three_selected": "You can select up to three items",
  "history": "History",
  "get_history_id_message": "You must select a user to display their history data.",
  "operation_name": "Operation",
  "cash_source": "Movement",
  "see_history": "See user History",
  "messages": "Messages",
  "see_messages": "See user Messages",
  "schedules": "Scheduled Messages",
  "message_title": "Message title",
  "message": "Message",
  "buttons": "Buttons",
  "guruve_buttons_add": "Add buttons to message",
  "section_title": "Section Title",
  "body_message": "Enter the form specifications.",
  "body_message_title": "Body of form",
  "expected_response_type": "Type of response",
  "list_text_message": "Name of the list",
  "guru_message_types": "Type of message",
  "guru_messages_status": "Status of the message",
  "total_sent": "Total sent messages",
  "send_date": "Date sent",
  "send_date_and_hour": "Date and hour of sent",
  "included_groups": "Includes groups",
  "excluded_groups": "Excluded groups",
  "included_users": "Includes users",
  "excluded_users": "Excluded users",
  "includ_all": "Includes all",
  "programing": "Programing",
  "targets": "Targets",
  "get_schedule_message_info": "You must select a message to display their configuration.",
  "duplicated": "Duplicate",
  "aprove": "Aprove",
  "reject": "Reject",
  "send_test": "Send Test",
  "send_date_and_hour_message": "Your message was programmed to be sent at the desired date and time, if you wish to modify it you can do so before the set time.",
  "change_message": "Change message",
  "click_and_drag": "Click or drag file to this area to upload",
  "click_and_drag_message": " Support for a single. Strictly prohibit from uploading company data or other band files",
  "changePriority": "Change Priority",
  "changeNormalToPriority": "Change to Normal Priority",
  "changeHighToPriority": "Change to High Priority",
  "priority": "Prioritized",
  "request_approval": "Request approval",
  "expired": "Expired",
  "building": "Building",
  "ready_for_approval": "Read for approval",
  "scheduled": "Approved and Scheduled",
  "sent": "Sent",
  "rejected": "Rejected",
  "cancelled": "Cancelled",
  "type_upload_method": "Upload method",
  "review_sub_type": "Review sub type",
  "assigned": "Assigned",
  "sending_message": "Sending message",
  "delivered": "Delivered",
  "received": "Received",
  "seen": "Seen",
  "text": "Text",
  "list": "List",
  "media": "Media",
  "button": "Button",
  "button_reply": "Button reply",
  "list_reply": "List reply",
  "sticker": "Sticker",
  "incoming": "Incoming",
  "no_message": "No message",
  "sent_at": "Sent at",
  "delivered_at": "Delivered at",
  "read_at": "Read at",
  "see_more": "See more",
  "information": "Information",
  "add_form_field": "Add form field",
  "add_list_item": "Add list item",
  "add_button_field": "Add button field",
  "edit_schecule_message": "Edit schedule message",
  "saving": "Saving...",
  "payment_method": "Payment method",
  "total_pending": "Total pending",
  "total_pending_priority": "Total pending priority",
  "edit_message_name": "Edit message name",
  "edit_schedule": "Edit content",
  "clear_last_selections": "Clear last selections",
  "total_user_bill_downloads": "Total number of logs completed",
  "estimated_upload_date": "Estimated upload date",
  "upload_month": "Upload month",
  "scheduled_at": "Scheduled at",
  "mode": "Mode",
  "assignee_charger": "Assignee charger",
  "assignee_payer": "Assignee payer",
  "digitation_status": "Digitizing status",
  "user_bill_download": "User bill download",
  "payment_aut": "Payment aut",
  "remove_children": "Remove children",
  "remove_children_account": "Remove of parent account",
  "action_sucesfull": "Action sucesfull",
  "remove_account_of_bill_parent": "Remove bill of account parent",
  "remove_pay_from_bill": "Do you want to remove this invoice from the payment?",
  "payment_method_suggest": "Payment method suggest",
  "direct_debit_payment": "Direct Debit Payment",
  "upload_parent_file_bill": "Upload parent file bill",
  "clickUploadBill": "Click to upload bill",
  "simple_mode": "Simple mode",
  "report_mode": "Report mode",
  "childs": "Childs",
  "parent": "Parent",
  "loader": "Loader",
  "add_selected": "Add selected",
  "automatic_debit": "Automatic debit",
  "energy_border": "Border",
  "account_cession": "Account cession",
  "account_cession_susecfull": "Account cession sucesfull",
  "accounts": "Accounts",
  "stratum": "Stratum",
  "position": "Position",
  "id_card": "Id card",
  "bill_upload": "Bill upload",
  "total files": "Total files",
  "difference": "Difference",
  "reconciled": "Reconciled",
  "notCancelled": "Not cancelled",
  "number_of_agreement": "Number of agreement",
  "date_ipp_ipc_base": "Date IPP/IPC base",
  "contributing_rate": "Contributing rate",
  "collection_method": "Collection method",
  "help": "Help",
  "rates_hepl_one": "Variables stored with a Hash/Pound symbol #",
  "rates_hepl_two": "Leave a space before declaring a variable",
  "rates_hepl_three": "Separate each data with comma for example: (#SMMLV + 30), (#SMMLV+30)*15",
  "rates_hepl_four": "When using a ternary operator remember to use '?' then ':' Example:",
  "rates_hepl_five": "It is used for an '&&' and what is used for an '||'",
  "rates_hepl_six": "In case a nested ternary doesn't have the last validation, please set the value to TRUE.",
  "public_lighting_fee": "Public lighting fee",
  "timely_payment_date_budget": "Estimated payment date budget",
  "daily_number": "Daily number",
  "filter_date": "Filter date",
  "ilumination_office": "Ilimination office",
  "aires_office": "Aires office",
  "ilumination_atm_office": "Ilimination ATM office",
  "aires_atm_office": "Aires ATM office",
  "aire_data_center": "Aire data center",
  "hydric": "Hydric",
  "init_date": "Init date",
  "end_date": "End date",
  "add_investments": "Add investments",
  "base_lines": "Base lines",
  "remove_cession": "Remove cession",
  "bot_upload_bill": "Bot upload bill",
  "implementation_upload_bill": "Implementation upload bill",
  "timely_payment_date": "Timely payment date",
  "consumption": "Consumption",
  "image_bill": "Image bill",
  "create_account": "Create account",
  "upload_bill": "Upload bill",
  "view_upload_bill_info": "View upload bill info",
  "account_relationship": "Account relationship",
  "message_template": "Message template",
  "template_message_intro": "Welcome! In this space, you will have the opportunity to choose the perfect template for your welcome message on WhatsApp.",
  "header": "Header",
  "messages_lobby": "Welcome to Guruve's message creation form! This is where you can configure and personalize your message to your liking.",
  "messages_lobby_1": "Choose and customize the welcome template for your message.",
  "messages_lobby_2": "Create messages according to your preferences.",
  "messages_lobby_3": "Interact with bot users by creating response conditions for the sent messages.",
  "budgetFixed": "Budget fixed",
  "conditions_title": "Conditions",
  "conditions_message_intro": "Here you can create conditions for the messages you send through Guruve, which can be used to validate messages, provide options for the user to select one alternative or another, and more.",
  "conditions_message_example": "These are examples of using a condition: $id_message.value === 'Yes', to query an age, for example, or validate if the invoice consumption is high: $invoice_consumption.value > '100000' or $age.value > '18', among many other queries and validations.",
  "message_intro": "Customize your messages to your liking to be sent through Guruve. You can send videos, images, and stickers.",
  "add_on_tree": "Add to message tree",
  "template_id": "Template ID",
  "user_information": "User information",
  "add_guru_mode_message": "To access this module, you must add a guruve mode to your user profile through the following link:",
  "dates_information": "Dates information",
  "origin_type": "Origin type",
  "start_date": "Start date",
  "destination_name": "Destination name",
  "no_interactions": "You must select an interaction to see the information of the sent messages.",
  "interactions": "Interactions",
  "unreconciledTransactions": "Unreconciled transactions",
  "export_users_bill_upload": "Export users bill upload",
  "export_sent_messages": "export sent messages",
  "points": "Points",
  "carbon_print": "Carbon print",
  "meters_variation": "Meters variation",
  "energy": "Energy",
  "see_carbon_footprint": "See carbon footprint",
  "alerts": "Alerts",
  "total_active_energy": "Total active energy",
  "total_reactive_energy": "Total reactive energy",
  "average_consumption": "Average consumption",
  "actual_savings": "Actual savings",
  "last_year": "Last year",
  "company_information": "Company information",
  "monthly_comparison": "Monthly comparison",
  "values": "Values",
  "energy_consumption": "Energy consumption",
  "reactive_power_consumption": "Reactive power consumption",
  "base_consumption": "Base consumption",
  "hide_accounts_without_data": "Hide accounts without data",
  "flow_finished": "Flow finished",
  "billUpload": "Bill upload",
  "massive_logbook": "Massive logbook",
  "provison_synlab_file": "Provison synlab file",
  "brand": "Brand",
  "relationship": "Relationship",
  "typeEquipment": "Equipment type",
  "lightingType": "Lighting type",
  "inventory": "Inventory",
  "account_changes": "Account changes",
  "mode_of_sale": "Mode of sale",
  "offer_number": "Offer number",
  "modality": "Modality",
  "investments": "Investments",
  "filter_base_consumption": "Filter base consumption",
  "jump_message_intro": "You can use jumps to jump to a specific message by using the message IDs.",
  "jump": "Jump",
  "projected": "Projected",
  "vertebracanmakethepay": "Vertebra can make the payment",
  "the_user": "The user",
  "old_account_change_marketer": "The user [user_make_change] made a change of supplier from [old_provider] to [new_provider] from account [old_account] to account [new_account] with XM registration [datexm] on [date]. The change is under the modality [modality] and the sales modality is [sales_modality].",
  "new_account_change_marketer": "The user [user_make_change] generated the change of marketer for the account [old_account] to the account [new_account] with Xm registration [datexm], the modification date is [date]",
  "document": "Document",
  "removeChangeMarketer": "Remove change marketer",
  "not_registered": "Not registered",
  "respel": "Respel",
  "internet": "Internet",
  "application": "Application",
  "change_date_to_regulatory_bodies": "Change date to regulatory bodies",
  "valve": "Valve",
  "message_name": "Message name",
  "multiple_company_schedule": "Multiple company schedule",
  "withoutBiReport": "You do not have reports assigned to this company",
  "unbilled_month": "Unbilled month",
  "invoice_account": "Invoice account",
  "see_childrens": "See childrens",
  "see_provider": "Service provider",
  "see_company": "See company",
  "parent_account": "Parent account",
  "Scheduled": "Scheduled",
  "schedule_messages": "Schedule messages",
  "extra_info": "Extra information",
  "message_extra_info": "Add some extra information to your message",
  "error_validation": "Error validation",
  "validation": "Validation",
  "startwith": "Start with",
  "endwith": "End with",
  "subtype": "Subtype",
  "optional_info": "Optional information",
  "message_optional_info": "Add some optional information to your message",
  "exeptionCommand_type": "Exception command type",
  "exeptionCommandText": "Exception command text",
  "sendNext": "Send next",
  "vertebra_can_pay": "Vertebra can pay",
  "type_place": "Type place",
  "changeMarketer": "Change marketer",
  "has_account_cession": "Account cession",
  "novelty_account": "Novelty account",
  "office_code": "Office code",
  "estimatePaymentDate": "Estimate payment date",
  "new_marketer": "New marketer",
  "withoutData": "Without data",
  "withoutInformation": "Without information",
  "street_lighting": "Street lighting",
  "cleanliness": "Cleanliness",
  "switchVerification": "Change verification",
  "insertbillpetition": "Insert bill petition",
  "upload_enel_file": "Upload enel file",
  "assigned_payer": "Assigned payer",
  "estimate_payment_date": "Estimate payment date",
  "initial_payment_date": "Initial payment date",
  "final_payment_date": "Final payment date",
  "issue_date_config": "Issue date config",
  "date_paid_config": "Date paid config",
  "billing_to": "Billing",
  "iva_value": "IVA value",
  "telemetry_value": "Telemetry value",
  "data_plan_vallue": "Data plan value",
  "dateChange": "Date change",
  "sell_modality": "Sell modality",
  "spot_date": "Spot date",
  "financing_date": "Financing date",
  "number_of_payments": "Number of payments",
  "final_pay_date": "Final pay date",
  "remove_change_marketer": "Remove change marketer",
  "export_interactions": "Export interactions",
  "export_unregistered_user_interactions": "Export interactions for unregistered users",
  "discounted_call": "Discounted call",
  "unregistered_users": "Unregistered users",
  "meter_characterization": "Meter characterization",
  "include_points_without_investments": "Include points without investments",
  "include_points_without_inventory": "Include points without inventory",
  "with": "With",
  "without": "Without",
  "error": "Error",
  "message_information": "Message information",
  "week": "Week",
  "budget_value": "Budget value",
  "budget_value_not_equal_total": "The budget value is not equal to the total of the budget items",
  "budget_account_date": "Budgeted date",
  "payment_immediate": "Payment immediate",
  "diferent_values": "Diferent values",
  "configure_service_percentages": "Configure service percentages",
  "service_percentages_for_budget_calculation": "Service percentages for budget calculation",
  "market": "Market",
  "estimate_payment_config": "Estimate payment config",
  "export_account": "Export account",
  "export_bills_history": "Export bills history",
  "bills_without_export": "Bills without export",
  "current": "Current",
  "previous": "Previous",
  "payment_today": "Payment today",
  "expires_today": "Expires today",
  "active_since": "Active since",
  "compare_budgets": "Compare budgets",
  "indexer": "Indexer",
  "base_date": "Base date",
  "adequacy_value": "Adequacy value",
  "equipment_value": "Equipment value",
  "labor_cost_value": "Labor cost value",
  "exit_penalty_percentage": "Exit penalty percentage",
  "year": "Year",
  "weeks_configuration_json": "JSON for weeks configuration",
  "payer_mode": "Payer mode",
  "reviewer_modo": "Reviewer mode",
  "audit_mode": "Audit mode",
  "bill_finder_mode": "Bill finder mode",
  "modes": "Modes",
  "recalculate_weeks": "Recalculate weeks",
  "last_bot_type": "Last bot type",
  "dNoHave": "Does not have",
  "configure_uploader_permissions": "Configure uploader permissions",
  "invoice_search": "Invoice search",
  "last_log": "Last log",
  "queueBudgetManagement": "Queue budget management",
  "manage": "Manage",
  "icon": "Icon",
  "remove_from_budget": "Remove from budget",
  "out_budget": "Out budget",
  "in_budget": "In budget",
  "roolback": "Roolback",
  "change_budget": "Change budget",
  "not_waiting": "Not waiting",
  "bill_arrive": "Bill arrive",
  "bill_not_arrive": "Bill not arrive",
  "arrives": "Arrives",
  "waiting": "Waiting",
  "assing_massive_groups": "Assign massive groups",
  "deactivate_users": "Deactivate users",
  "active_users": "Active users",
  "remenber": "Remember",
  "assing_many_groups_warning": "Groups will be assigned to users who meet the filter range criteria.",
  "image": "Image",
  "duplicated_flow_end": "Duplicated - flow end",
  "duplicated_flow_not_end": "Duplicated - flow not end",
  "section": "Section",
  "extra": "Extra",
  "old_provider": "Old provider",
  "new_provider": "New provider",
  "old_account": "Old account",
  "new_account": "New account",
  "registre_date": "Registre date",
  "sales_modality": "Sales modality",
  "change_marketer": "Change marketer",
  "user_make_change": "User make change",
  "market_type": "Market type",
  "assigned_real_date": "Assigned real date",
  "expires_at": "Expires at",
  "pin": "Pin",
  "link": "Link",
  "no_reclassification": "Do not use reclassification",
  "guru_type": "Guru type",
  "define_template": "Define template",
  "guruve_mode": "Guruve mode",
  "template": "Template",
  "optional": "Optional",
  "header_description": "Choose the type of multimedia content you will use for the Header of your message. (only one can be chosen)",
  "header_type": "Header type",
  "none": "None",
  "video": "Video",
  "body": "Message body",
  "body_description": "To customize the body of the message, enter the text you want to display. You can use variables to further customize the message. Variables are inserted as follows: {{variable number}}. Example: {{1}}. Make sure to assign variable numbers in ascending order so that the message is displayed correctly.",
  "characters": "Characters",
  "add_variable": "Add variable. Also, you can press the # key to display a menu with various options.",
  "actions": "Actions",
  "actions_description": "Define the type of action you want to show when the user receives the message.",
  "comment_cannot_contain_quotes": "The comment cannot contain single or double quotes",
  "add_log": "Add log",
  "created_by": "Created by",
  "log_today": "Today's log",
  "ipp_ipc_date_configuration": "IPP/IPC date configuration",
  "telephony": "Telephony",
  "television": "Television",
  "con_ocr": "With OCR",
  "reason_user_charge": "User charge reason",
  "novelty_prevents_invoice_search": "Novelty prevents invoice search",
  "inconsistent_charger": "Inconsistent charger",
  "manageCredentials": "Manage credentials",
  "public_lighting_short": "P. Lighting",
  "all_chargers": "All chargers",
  "office_type": "Office type",
  "bot_statistics": "Bot statistics",
  "bots_executed": "Bots executed",
  "exitosos": "Successful",
  "error_types": "Error types",
  "without_invoice": "Without invoice",
  "error_count": "Error count",
  "error_type": "Error type",
  "emails": "Emails",
  "generate_official_letter": "Generate official letter",
  "add_accounts": "Add accounts",
  "remove_accounts": "Remove accounts",
  "at_claiming": "At claiming",
  "remove_claim": "Remove claim",
  "open_invoice_history": "Open invoice history",
  "invoice_history": "Invoice history",
  "issue_date": "Issue date",
  "suspension_date": "Suspension date",
  "no_invoice_selected": "No invoice selected",
  "created_by_bulk_logs": "Created by bulk logs",
  "full_company_name": "Full company name",
  "guilty": "Guilty",
  "page_error": "Page error",
  "in_process": "In process",
  "total_attempts": "Total attempts",
  "view_all_details": "View all details",
  "completeds": "Completed",
  "created_provider_ticket": "Created provider ticket",
  "file_number": "File number",
  "created_from": "Created from",
  "updateNoveltyAccount": "Edit account issue",
  "consumption_calculation": "Consumption calculation",
  "unit_cost": "Costo unitario",
  "tariff_recovery_cost": "Tariff recovery cost",
  "view_bills": "View bills",
  "uploader_error": "File whit errors",
  "uploader_error_subtitle": "Please check and modify the file information before uploading it again",
  "uploader_error_description": "The content you submitted has the following errors",
  "uploader_redirect_dashboard": "Return to dashboard",
  "status_report": "Status report",
  "upload_massive_file": "Upload massive file",
  "is_multiple": "Is multiple",
  "provider_inactive_since": "This provider has been inactive since",
  "selected_point": "Selected point",
  "not_selected": "Not selected",
  "zip_conditions": "To load the data correctly, make sure that: \n* The files in the ZIP match the template format. \n* The PDF files have the format country_code-phone_number.format. \nexample: 573XXXXXXXXX.pdf",
  "donwloadZip": "Download Zip",
  "visitado": "Visited",
  "edit_investments": "Edit investments",
  "total_rows": "Total rows",
  "total_ok": "Total ok",
  "total_error": "Total error",
  "last_update": "Last update",
  "job_id": "Job ID",
  "entity": "Entity",
  "proccess_and_ignore_errors": "Process and ignore errors",
  "this_process_can_take_a_while": "This process can take a while",
  "download_report": "Download report",
  "display_errors": "Display errors",
  "payment_information": "Payment information",
  "searching_information": "Searching information",
  "eguru": "Eguru",
  "additional_days": "Additional days",
  "add_issue": "Add issue",
  "account_issue": "Account issue",
  "invoice_issue": "Invoice issue",
  "internal_use_issue_vertebra": "Vertebra internal use issue",
  "budget_issue": "Budget issue",
  "closed_account_issue": "Closed account issue",
  "change_bill": "Change bill",
  "invoice_value_affecting_account_issue": "Account issue affecting invoice value",
  "updated_at": "Updated at",
  "total_cargada": "Total loaded",
  "total_digitadas": "Total typed",
  "total_no_facturadas": "Total unbilled",
  "increase_alerts": "Increase alerts",
  "decrease_alerts": "Decrease alerts",
  "average_alerts": "Average alerts",
  "total_alerts": "Total alerts",
  "view_alerts": "View alerts",
  "alert_type": "Alert type",
  "alert_status": "Alert status",
  "alert_month": "Alert month",
  "current_consumption": "Current consumption",
  "comparison_consumption": "Consumption comparison",
  "variation": "Variation",
  "return_to_average": "Return to average",
  "last_year_consumption": "Last year consumption",
  "alerts_chart": "Year consumption",
  "perform_tracking": "Perform tracking",
  "ticket": "Ticket",
  "registration_successful": "Registration successful",
  "mark_as_not_real": "Mark as not done",
  "mark_as_not_real_description": "Alert marked as not real",
  "marked_as_not_real": "Marked as not real",
  "is_real": "Is real",
  "digitation_error": "Typing error",
  "new_status": "New status",
  "file_will_arrive_soon": "The file will arrive in a few minutes to the email",
  "revert_closed": "Revert closed",
  "confirm_revert_status": "Are you sure you want to revert the status of this alert?",
  "last_tracking_date": "Last tracking date",
  "showing": "Showing",
  "hiding": "Hiding",
  "parent_accounts": "Parent accounts",
  "child_accounts": "Child accounts",
  "other_accounts": "Other accounts",
  "parent_and_child_accounts": "Parent and child accounts",
  "accounts_excluding_children": "Accounts excluding children",
  "accounts_excluding_parents": "Accounts excluding parents",
  "indifferent": "Indifferent",
  "all_companies": "All companies",
  "load_another_bill": "Load another bill",
  "view_meter_alerts": "View meter alerts",
  "meter_alerts": "Meter alerts",
  "increase": "Increase",
  "decrease": "Decrease",
  "net_balance": "Net balance",
  "maximum": "Maximum",
  "optimal_maximum": "Optimal maximum",
  "average": "Average",
  "minimum": "Minimum",
  "optimal_minimum": "Optimal minimum",
  "chart": "Chart",
  "view_full_screen": "View full screen",
  "last": "Last",
  "tracking": "Tracking",
  "parent_company": "Parent company",
  "next": "Next",
  "mark_as_not_real_question": "Are you sure you want to mark this alert as not real? This will also close it",
  "comparison": "Comparison",
  "average_1_year": "Average 1 year",
  "average_6_months": "Average 6 months",
  "insertFilePlaneClaro": "Insert Claro Plane File",
  "add_filters": "Add filters",
  "remove_filter": "Remove filter",
  "filter_limit_reached": "Filter limit reached",
  "save_filter_settings": "Save filter settings",
  "addUserCredentials": "Add user credentials",
  "save_filter_prompt": "If you accept, the current filter configuration will be saved. Each time you open this module for the first time, this configuration will be loaded",
  "ignore_atypical_consumption": "Ignore atypical consumption",
  "view_tracking": "View tracking",
  "scheduled_start": "Scheduled start",
  "scheduled_end": "Scheduled end",
  "total_lesson": "Total lesson",
  "progress": "Progress",
  "eguru_courses": "Eguru courses",
  "instances": "Instances",
  "category": "Category",
  "course_setup": "Course setup",
  "complete_all_fields": "Complete all fields",
  "customize_your_course": "Customize your course",
  "lessons": "Lessons",
  "course_name": "Course name",
  "course_description": "Course description",
  "course_image": "Course image",
  "course_chapters": "Course chapters",
  "drag_and_drop_to_reorder_chapters": "Drag and drop to reorder chapters",
  "active_course": "Active course",
  "no_chapters": "No chapters",
  "add_module": "Add module",
  "module_name": "Module name",
  "add_module_name": "Add module name",
  "add_chapter": "Add chapter",
  "compliance": "Compliance",
  "civil_liability": "Civil liability",
  "offer_sincerity": "Seriousness of Offer",
  "insurance_policy": "Insurance policy",
  "subservices": "Subservices",
  "total_lessons": "Total lessons",
  "contend": "Contend",
  "datail": "Datail",
  "last_access": "Last access",
  "instance_status": "Instance status",
  "on_progress": "On progress",
  "previous_status": "Previous status",
  "deactivate_account": "Deactivate account",
  "account_deactivated_successfully": "The account has been successfully deactivated",
  "active_period": "Active period",
  "case_title": "Title for the case",
  "create_odoo_ticket": "Create Odoo ticket",
  "configure_periods": "Configure periods",
  "add_date_range": "Add date range",
  "thermal_energy": "Thermal energy",
  "total_lessons_completed": "Total lessons completed",
  "view_comments": "View comments",
  "total_completed": "Total completed",
  "in_progress": "In progress",
  "comments_instance": "Comments instance",
  "total_comments": "Total comments",
  "reload": "Reload",
  "comments_description": "Here you can find the comments for each lesson of the instance. If a lesson has no comments, nothing will appear to show.",
  "is_completed": "Is completed",
  "lesson": "Lesson",
  "contend_time": "Contend time",
  "id_video": "Id video",
  "upload_video": "Upload video",
  "total_pages": "Total pages",
  "add_lesson": "Add lesson",
  "courses": "Courses",
  "add_lessons_to_save": "Add lessons to save the course",
  "max_characters_allowed": "Maximum {{charactersAllowed}} characters allowed",
  "successful_export": "Successful export",
  "info_will_arrive_in_minutes_to_email": "The information will arrive in a few minutes to the email {{email}}",
  "used_massive_log": "Used massive log",
  "total_reviewed": "Total reviewed",
  "total_no_reviewed": "Total no reviewed",
  "no_reviewed_days": "No reviewed days",
  "not_review_in_a_last_two_days": "Not reviewed in a last two days",
  "subject": "Subject",
  "from_email": "From email",
  "has_attachment": "Has attachment",
  "attachment": "Attachment",
  "is_reviewed": "Is reviewed",
  "from": "From",
  "to": "To",
  "to_me": "To me",
  "replay_to": "Replay to",
  "bot_reviewed": "Bot reviewed",
  "comment": "Comment",
  "remove_review": "Remove review",
  "edit_review": "Edit review",
  "no_reviewed_day": "No reviewed day",
  "bot_not_reviewed": "Bot not reviewed",
  "searchBills": "Search bills",
  "bots_execution": "Bots execution",
  "export_policy": "Export policy",
  "measurement_location": "Measurement location",
  "max_budget": "Max budget",
  "max_value": "Max value",
  "digital_status": "Digital status",
  "total_accounts": "Total accounts",
  "total_payments": "Total payments",
  "execute_accounts_in_this_cluster": "Execute accounts in this cluster",
  "image_upload_flag": "Image is not mandatory in this mode. If an image is uploaded, it will only appear in the header of the text.",
  "upload_attachment": "Upload attachment",
  "tags": "Tags",
  "include_policy": "Include policy",
  "export_only_policy": "Export only policy",
  "attachments": "Attachments",
  "more": "More",
  "total_users": "Total users",
  "downloaded_files": "Downloaded files",
  "parents_only": "Parents only",
  "forum": "Requires comment to complete lesson.",
  "file_info": "File information",
  "file_name": "File name",
  "size_file": "File size",
  "upload_bill_description": "Upload bill to an account",
  "upload_bill_description_one": "Obtain a copy of the invoice that you want to upload to the account.",
  "upload_bill_description_two": "Verify the details of the account to which the invoice will be charged, including the account number, the company, and other information.",
  "account_info": "Account information",
  "see_last_bill": "See last bill",
  "last_bill": "Last bill",
  "automatically_upload": "Automatically upload",
  "upload_date": "Upload date",
  "see_upload_bills_view": "See uploaded bills",
  "see_bill": "See bill",
  "report_generated_successfully": "Report generated successfully",
  "executions_last_60_min": "Executions last 60 min",
  "available_executions": "Available executions",
  "go_back": "Go back",
  "perform_new_execution": "Perform new execution",
  "end": "End",
  "execute": "Execute",
  "select_accounts": "Select the accounts",
  "selected": "Selected",
  "are_you_sure_to_select": "Estás seguro de seleccionar",
  "bot_executed": "Bot executed",
  "cancel_execution": "Cancel execution",
  "without_cluster": "Without cluster",
  "execute_cluster_and_without_cluster": "Execute cluster {{cluster}} and those without cluster",
  "remove_selection": "Remove selection",
  "last_execution": "Last execution",
  "account_change": "Account change",
  "massive_novelties": "Massive novelties",
  "close_novelties": "Close novelties",
  "close_massive_novelties_confirm": "If you accept all the massive novelties of this execution, they will be closed",
  "add_massive_comments": "Add massive comment",
  "invoice_uploader": "Invoice uploader",
  "created_by_cluster": "Created by cluster",
  "invoices_not_uploaded_this_month": "Invoices not uploaded this month",
  "urgent": "Urgent",
  "operation_type": "Operation type",
  "are_you_sure": "Are you sure?",
  "if_chose_search_bills_tab_you_lose_email_filters": "If you choose the search bills tab, you will lose the email filters",
  "addDocuments": "Add documents",
  "document_type": "Document type",
  "view_document": "View document",
  "no_data": "No data",
  "add_document": "Add document",
  "legal_name": "Legal name",
  "nit": "NIT",
  "document_expiration_date": "Document expiration date",
  "see_actual_bill": "See actual bill",
  "bill_already_uploaded": "Bill already uploaded",
  "bill_already_uploaded_description": "The bill has already been uploaded to the account",
  "add_item": "Add item",
  "hide_senders": "Hide senders",
  "hide_senders_prompt": "Here you can hide emails from senders. Add the email address you want to exclude to the list.",
  "clear_filters": "Clear filters",
  "stats": "Stats",
  "odoo_ticket_id": "Odoo ticket ID",
  "ticket_type": "Ticket type",
  "model": "Model",
  "success_message": "The list of hidden emails has been updated",
  "review_result": "Review result",
  "order_by": "Order by",
  "review_duplicate_bill": "Review duplicate bill",
  "non_billing_email": "Non billing email",
  "child": "Child",
  "id": "ID",
  "attachments_type": "Attachments type",
  "ticket_code": "Ticket code",
  "add_all_the_accounts": "Add all the accounts",
  "add_all_the_accounts_confirm": "If you accept, all accounts that comply with these filters will be added",
  "bots_will_be_executed_for": "Bots will be executed for",
  "of_them_will_run_as_doubles": "of them will run as doubles",
  "invoice_fields": "Invoice fields",
  "no_emails_selected": "No emails selected",
  "select_emails_to_view": "Select the emails to view",
  "no_emails_data": "No emails data",
  "inactive_account": "Inactive account",
  "daily": "Daily",
  "weekly": "Weekly",
  "monthly": "Monthly",
  "custom": "Custom",
  "today": "Today",
  "TwoDays": "Two days",
  "SevenDays": "Seven days",
  "30Days": "30 days",
  "non_existent": "Non existent",
  "status_previously_listed": "Already loaded",
  "the_bill_will_upload_in_the_account": "The bill will upload in the accounts",
  "multiple_bill_upload": "Multiple bill upload",
  "bill_uploaded": "Bill uploaded",
  "the_bill_should_be_uploaded_from_the_main_account": "The bill should be uploaded from the main account",
  "the_bill_should_be_pay_from_the_main_account": "The bill should be pay from the main account",
  "block_subject": "Block subject",
  "block_email": "Block email",
  "add_hidden_item": "Add hidden item",
  "empty_hidden_fields": "Please add at least one hidden item",
  "other_concepts": "Other concepts",
  "credit_note": "Credit note",
  "original_sender": "Original sender",
  "clean": "Clean",
  "visit_date": "Visit date",
  "orden": "Orden",
  "error_subtitle": "Opps! An error occurred",
  "error_description": "An error occurred while loading the data. Please try again later.",
  "visit_count": "Visit count",
  "date_filing": "Date filing",
  "expiration_date": "Expiration date",
  "no_documents": "No documents",
  "initial_balance": "Initial balance",
  "last_invoice_uploaded": "Last invoice uploaded",
  "company_assignment": "Company assignment",
  "company_assigned_successfully": "Company assigned successfully",
  "success": "Success",
  "accounts_petitions": "Account petition",
  "company_name": "Company name",
  "marketer_name": "Marketer name",
  "marketer_email": "Marketer email",
  "has_bill": "Has bill",
  "first_send": "First send",
  "retries": "Retries",
  "last_attempt": "Last attempt",
  "uploaders": "Uploaders",
  "details": "Details",
  "open_extras": "Open extras",
  "one_month_comparation": "One month comparation",
  "one_month_variation": "One month variation",
  "one_month_variation_percent": "One month variation (%)",
  "three_months_comparation": "Three months comparation",
  "three_months_variation": "Three months variation",
  "three_months_variation_percent": "Three months variation (%)",
  "six_months_comparation": "Six months comparation",
  "six_months_variation": "Six months variation",
  "six_months_variation_percent": "Six months variation (%)",
  "annual_comparation": "Annual comparation",
  "annual_variation": "Annual variation",
  "annual_variation_percent": "Annual variation (%)",
  "alert_value_percent_max_limit_message": "The current value exceeds {{percent}}% of variation compared to the {{comparison}}",
  "annual_comparison": "Previous year",
  "one_month_comparison": "Previous month",
  "six_months_comparison": "6 months ago",
  "three_months_comparison": "3 months ago",
  "triggered_alerts": "Triggered alerts",
  "details_of": "Details of",
  "time_elapsed": "Time elapsed",
  "digitation_role": "Digitation role",
  "select_digitation_role": "Select a digitation role",
  "bill_alerts": "Bill alerts",
  "status_last_followup": "Status last followup",
  "pending": "Pending",
  "managed": "Managed",
  "current_value": "Current value",
  "seccess": "Success",
  "operationSuccessful": "Operation successful",
  "schedule_message": "Schedule message",
  "schedule_message_placeholder": "Write your message here",
  "add_message": "Add message",
  "add_a_list_of_messages": "To add a list of messages or a single message, click the + icon on the top right corner of the screen.",
  "bots_with_very_soon_execution": "Bots with very soon execution",
  "issue_extra_days": "Extra days of execution",
  "tv_telephony": "TV and Telephony",
  "no_bill_selected": "No bill selected",
  "select_bill_to_review": "Please select a bill to review",
  "date_begin": "Begin date",
  "date_end": "End date",
  "date_bill_uploaded": "Bill uploaded date",
  "extra_days": "Extra days",
  "days": "days",
  "days_of_execution": "Days of execution",
  "extra_days_of_execution": "Extra days of execution",
  "select_one_day": "Select one day",
  "select_extra_days": "Select extra days",
  "select_days_of_execution": "Select days of execution",
  "select_extra_days_of_execution": "Select extra days of execution",
  "reviewed_at": "Reviewed at",
  "assing_many_reviews_warning": "This action will review all the bills with the selected filters. Are you sure you want to continue?",
  "max_amount": "Max amount",
  "child_account": "Child account",
  "show_parents": "Show parents",
  "show_childs": "Show children",
  "overdue_payment": "Overdue payment",
  "is_claiming": "Is cleaming",
  "service_name": "Service name",
  "select_record_the_change": "Select record the change",
  "user_makinguthe_change": "User making the change",
  "operation_date": "Operation date",
  "review_many_bills": "Review many bills",
  "user_in_charge": "User in charge",
  "payment_variation": "Payment variation",
  "has_debt": "Has debt",
  "reviewed": "Reviewed",
  "no_schedules": "No schedules",
  "upload_excel": "Upload excel",
  "collection_office": "Collection office",
  "originator_nit": "Originator NIT",
  "check_value": "Check value",
  "bank_movements": "Bank movements",
  "upload_bank_movements": "Upload bank movements",
  "are_you_sure_to_upload_this_file": "Are you sure to upload this file?",
  "product": "Product",
  "automatic_review": "Automatic review",
  "initial_and_final_payment_dates": "Initial and final payment dates",
  "accountProvider": "Proveedor de cuenta",
  "pressEnterOrComma": "Press Enter or comma",
  "view_bank_movements": "View bank movements",
  "noOCR": "No OCR",
  "add_new_blocked_category": "Add new blocked category",
  "blocked_categories_blacklist": "Blocked categories (Blacklist)",
  "add_new_enabled_category": "Agregar nueva categoría habilitada",
  "enabled_categories_whitelist": "Enabled categories (Whitelist)",
  "category_added_successfully": "Category added successfully",
  "category_removed_successfully": "Category removed successfully",
  "error_removing_category": "Error removing category",
  "this_category_already_exists_in_one_of_the_lists": "This category already exists in the destination list!",
  "please_enter_a_category_name": "Please enter a category name",
  "this_category_already_exists_in_the_destination_list": "This category already exists in the destination list!",
  "category_moved_successfully": "Category moved successfully",
  "configure_white_and_blacklist": "Configure whitelist and blacklist",
  "temp_pay": "Temp pay"
}
